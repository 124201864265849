export default function deepFind(obj : any, path : string) {
    if (!path) return null;

    var paths = path.split("."),
        current = obj,
        i;

    for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
            return undefined;
        } else {
            current = current[paths[i]];
        }
    }

    return current;
}
