import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";
import { ReadLibraryPart } from "./libraryPartService";
import { ReadLibraryPiece } from "./libraryPieceService";

const apiEndpoint = "/LibraryViewer";

export interface ReadLibraryViewer{
    piece : ReadLibraryPiece;
    parts : ReadLibraryPart[];
}

export async function GetLibraryViewers( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadLibraryViewer>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadLibraryViewer>>(apiEndpoint + "/libraryViewers", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}


const libraryViewerService = {
    GetLibraryViewers
};

export default libraryViewerService;
