import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import Button, { ButtonType } from '../components/common/Button';
import withRouter from '../utils/withRouter';
import partInstrumentService, { ReadPartInstrument } from '../services/partInstrumentService';
import PartInstrumentsTable from './components/partInstrumentsTable';
import Permission from '../components/common/Permission';
import { toast } from 'react-toastify';

interface PartInstrumentsState{
    pagedData : Paginated<ReadPartInstrument>,
    sortColumn : Column<ReadPartInstrument>,
    //filters: Map<string, string>;
}

class LocPartInstruments extends Component< any, any, PartInstrumentsState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "instrumentName", label: "Instrument Name", order: "asc" },
        filters: new Map<string, string>()
    }

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
         return libraryId;
    }

    PieceId = () : bigint => {
        const { partId } = this.props.router.params;
         return partId;
    }

    PartId = () : bigint => {
        const { partId } = this.props.router.params;
         return partId;
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadPartInstrument>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadPartInstrument>, filters : Map<string, string> ) =>
    {
        filters.set( "partId", String(this.PartId()));
        const pagedData = await partInstrumentService.GetPartInstruments(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadPartInstrument) => {
        await partInstrumentService.DeletePartInstrument(keyValue!);
        toast.success( "Instrument " + keyValue?.instrumentName + " removed from " + keyValue?.partName);
        this.componentDidMount();
    }

    HandleDiscoverClick = async () => {
        await partInstrumentService.AutoPopulate(this.PartId());
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="AddInstrumentToPart">
                    <div>
                        <Button className="me-1" buttonType={ButtonType.primary} to="add">Add</Button>
                        <Button buttonType={ButtonType.primary} onClick={this.HandleDiscoverClick} >Discover</Button>
                    </div>
                    <hr/>
                </Permission>
                <PartInstrumentsTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const PartInstruments = withRouter(LocPartInstruments);


export default PartInstruments;
