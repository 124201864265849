import httpService from "./httpService";
import { Paginated } from "./Paginated";
import { GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";

const apiEndpoint = "/User";

export interface IUserBase {
    firstName : string;
    lastName : string;
    middleNames : string;
    email : string;
}

export interface GetUser extends IUserBase{
    id : bigint;
    guid : string;
    displayName : string;
    created : Date;
    lastUpdated : Date;
    emailConfirmed : boolean;
}

export async function getUsers( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<GetUser>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<GetUser>>(apiEndpoint + "/users", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function getUser( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<GetUser>(apiEndpoint + "/user?" + params );

    return data;
}

export async function postUser(firstName : string, middleNames : string, lastName : string, email: string): Promise<void>{
    await httpService.post(apiEndpoint + "/user", {
        firstName,
        middleNames,
        lastName,
        email
    });
}

export async function putUser( id : GeneralIdRef, firstName : string, middleNames : string, lastName : string, email: string, newPassword : string, emailConfirmed : boolean): Promise<void>{
    await httpService.put(apiEndpoint + "/user", {
        id,
        firstName,
        middleNames,
        lastName, 
        email,
        newPassword,
        emailConfirmed
    });
}

export async function deleteUserByEmail( email?:string): Promise<void>{
    
    await httpService.delete(apiEndpoint + "/userByEmail", { data: email } );
}

export async function deleteUser( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/user", { data: { generalIdRef } } );
}

export async function resendConfirmEmail(id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.post(apiEndpoint + "/resendConfirmEmail", generalIdRef);
}

const userService = {
    getUsers,
    getUser,
    postUser,
    putUser,
    deleteUser,
    deleteUserByEmail,
    resendConfirmEmail
};

export default userService;
