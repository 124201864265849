import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import securityService, { CreateRole, EditRole } from "../services/securityService";
import { MakeGeneralIdRef } from "../utils/GeneralIdRef";
import { getParentPath } from "../utils/getParentPath";

interface AddRoleState extends FormState {
    data: {
        name: string;
    };
    redirect: string;
}

class LocAddRole extends Form<any, any, AddRoleState> {
    state: AddRoleState = {
        data: {
            name: ""        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName)
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { name } = this.state.data;

            if (this.isEditMode()) {
                const { roleId } = this.props.router.params;

                const editRole : EditRole = {
                    id : MakeGeneralIdRef( roleId),
                    name: name,
                    isAdministrator: false,
                    isSuperUser : false
                };

                await securityService.PutRole(editRole);
                toast.info("Role edited");
            } else {
                const createLibrary : CreateRole = {                    
                    name: name,
                    isAdministrator: false,
                    isSuperUser: false    
                }

                await securityService.PostRole(createLibrary);
                toast.info("New Role added");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/security" });
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const { roleId } = this.props.router.params;

        if (roleId !== undefined) {
            try {
                const loadedData = await securityService.GetRole(roleId);

                const { data } = this.state;
            
                data.name = loadedData.name;

                this.setState({ data });
            }
            catch(ex:any) {
                this.handleFatalError(ex)
            }
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Role</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const AddRole = withRouter(LocAddRole);

export default AddRole;
