import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadLibrary } from "../../services/libraryService";
import authenticationService from "../../services/authenticationService";

class LibrariesTable extends React.Component<PublishedTableProps<ReadLibrary>> {
    columns : Column<ReadLibrary>[] = [
        { key: "name", label: "Name", order: "asc", link:"{0}" },
        { key: "folderName", label: "FolderName", order: "asc" },
        { key: "published", label: "Published", order: "asc", searchable:false, content: (item) => {return (<>{String(item!.published)}</>)} }
    ];

    raiseSort = (sortColumn : Column<ReadLibrary>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const editPath = authenticationService.hasAccess("EditLibrary") ? "{0}/edit" : undefined
        const doDelete = authenticationService.hasAccess("DeleteLibrary") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default LibrariesTable;