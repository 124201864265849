import { Component } from "react";
import withRouter, { RouterProps } from "../../utils/withRouter";
import securityService, { ReadRole, ReadUserRole } from "../../services/securityService";
import Column from "../../components/common/columns";
import { Paginated } from "../../services/Paginated";
import UserRolesTable from "./UserRolesTable";
import Button, { ButtonType } from "../../components/common/Button";
import Permission from "../../components/common/Permission";

interface UserRolesTabProps extends RouterProps {
    role : ReadRole
}

interface UserRolesTabsState {
    pagedData : Paginated<ReadUserRole>,
    sortColumn : Column<ReadUserRole>,
    filters: Map<string, string>;
}

class LocUserRolesTab extends Component< UserRolesTabProps, any, UserRolesTabsState> {
    state : UserRolesTabsState = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "rolename", label: "Role Name", order: "asc" },
        filters: new Map<string, string>()
    }

    userId = () => {
        const { userId } = this.props.router.params;
        return userId;
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadUserRole>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadUserRole>, filters : Map<string, string> ) =>
    {
        filters.set("user.Id", String(this.userId()));

        const pagedData = await securityService.GetUserRoles(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( userRole? : ReadUserRole) => {
        await securityService.DeleteUserRole( userRole!.userId, userRole!.roleId);
        this.componentDidMount();
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;        
        //const { role } = this.props.router.params;

        return (
            <div>
                <h2>Role Users</h2>
                
                <Permission privilegeKey="CreateUserRole">
                    <div>
                        <Button className="me-1" buttonType={ButtonType.primary} to="role/add">Add</Button>
                    </div>
                    <hr/>
                </Permission>
                <UserRolesTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const UserRolesTab = withRouter(LocUserRolesTab);


export default UserRolesTab;
