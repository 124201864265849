import * as React from "react";
import Button, { ButtonType } from "../components/common/Button";

function NotFound() {
    return <>
        <h1>Not found</h1>
        <p>I've had a look down the back of the sofa, under the rug and in the draw of random stuff.   Still can't find it, sorry.</p>
        <Button buttonType={ButtonType.link} to="/">Go Home</Button>
    </>;
}

export default NotFound;
