import React from "react";
import Column from "../../components/common/columns";
import Table, { PublishedTableProps } from "../../components/common/Table";
import { GetUser } from "../../services/usersService";
import authenticationService from "../../services/authenticationService";
import Button, { ButtonType } from "../../components/common/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export interface UsersTableProps extends PublishedTableProps<GetUser>
{
    resendConfirmEmail?: ( user : GetUser ) => void;
}

class UsersTable extends React.Component<UsersTableProps> {
    columns : Column<GetUser>[] = [
        { key: "displayName", label: "Name", order: "asc" },
        { key: "email", label: "Email", order: "asc" },
        { key: "domainName", label: "Domain", order: "asc" },
        { key: "created", label: "Created", order: "asc" },
        { key: "lastUpdated", label: "Last Updated", order: "asc" },
        { key: "emailConfirmed", label: "Resend Confirm", order: "asc", searchable: false, content: (item) => {
                if (!item!.emailConfirmed) {return (<><Button buttonType={ButtonType.secondary} onClick={()=>this.resendConfirmEmail(item)}><FontAwesomeIcon icon={faEnvelope} /> Confirm</Button></>)} return (<></>)}
        }
    ];

    resendConfirmEmail = (keyValue: GetUser) => {
        const { resendConfirmEmail } = this.props;

        if (resendConfirmEmail != null)
            resendConfirmEmail(keyValue!);
    }
    
    render() { 
        const { data, sortColumn, onSort, onChangePage, onSearch, onDelete } = this.props;

        const editPath = authenticationService.hasAccess("EditUser") ? "{0}" : undefined
        const doDelete = authenticationService.hasAccess("DeleteUser") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={onSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default UsersTable;