export enum EmailActionType {
    PasswordReset = 0,
    DisableAuthenticator = 1,
    ConfirmEmailAddress = 2,
}

export interface IEmailUserAction {
    email: string;
    token: string;
    password: string;
    emailActionType: EmailActionType;
}
