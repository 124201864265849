import React from 'react';
import Button, { ButtonType } from './Button';

interface TabHeaderProps{
    isActive : boolean,
    label : string
    onClick : (label:string) => void;
}

class TabHeader extends React.Component<TabHeaderProps> {

    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
      };

    render() {
        const {
            onClick,
            props: { isActive, label },
          } = this;

          let className = "tab-list-item";

          if (isActive === true) {
            className += " tab-list-active";
          }

        return (
            <li className={className}>
                 <Button className={className} buttonType={ButtonType.theme} onClick={onClick}>{label}</Button>                
            </li>
        );
    }
}

export default TabHeader;