import React from "react";
import Button, { ButtonType } from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import "./expando.css"

interface ExpandoProps {
    name:string,
    title : JSX.Element,
    children:JSX.Element,
    error: string;
}
 
interface ExpandoState {
    expanded : boolean;
}
 
class Expando extends React.Component<ExpandoProps, ExpandoState> {
    state : ExpandoState = { 
        expanded : false
     }

    DropDownClick = () => {
        this.setState({expanded :true})
    }

    CloseUpClick = () => {
        this.setState({expanded :false})
    }

    render() { 
        const { title, children } = this.props;
        const { expanded } = this.state;

        if (!expanded){
            return ( <div className="bordered">
                <Button className="btn-spaced" buttonType={ButtonType.secondary} onClick={this.DropDownClick}>{title} <FontAwesomeIcon icon={faCaretDown}/></Button>
            </div> );
        }
        else {
            return ( <div className="bordered">
                <Button className="btn-spaced" buttonType={ButtonType.secondary} onClick={this.CloseUpClick}>{title} <FontAwesomeIcon icon={faCaretUp}/></Button>
                {children}
            </div> );
        }
    }
}
 
export default Expando;