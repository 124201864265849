import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import Button, { ButtonType } from '../components/common/Button';
import withRouter from '../utils/withRouter';
import RolesTable from './components/rolesTable';
import HorizontalTabs from '../components/common/HorizionalTabs';
import Tab from '../components/common/Tab';
import securityService, { ReadRole } from '../services/securityService';
import RoleUsers from './components/RoleUsers';
import RolePrivileges from './components/RolePrivileges';

interface RoleDetailsState{
    role : ReadRole;
    // pagedData : Paginated<ReadPartInstrument>,
     //sortColumn : Column<ReadPartInstrument>,
    //filters: Map<string, string>;
}

class LocRoleDetails extends Component< any, any, RoleDetailsState> {
    state : RoleDetailsState = {
        role : {
            guid:"",
            id:BigInt(0),
            isAdministrator: false,
            isSuperUser: false,
            name: ""}
        // pagedData : { page: 1,
        //     pageSize : 10,
        //     count: 0,
        //     totalPages: 1,
        //     data: [] },
        // sortColumn: { key: "instrumentName", label: "Instrument Name", order: "asc" },
        // filters: new Map<string, string>()
    }

    componentDidMount = async() => {
        const { roleId } = this.props.router.params;

        if (roleId !== undefined) {
            const loadedData = await securityService.GetRole(roleId);
          
            const role = loadedData;
            this.setState({ role });
        }
    }

    // componentDidMount = async () => {
    //     const { page, pageSize } = this.state.pagedData;
    //     await this.changePage(page, pageSize);
    // }

    //  changePage = async(page: number, pageSize : number) =>{
    //     const { sortColumn, filters } = this.state;
    //     await this.updatePage(page, pageSize, sortColumn, filters);
    // }

    // onSort = async(sortColumn : Column<ReadRole>) => {
    //     const {page, pageSize } = this.state.pagedData;
    //     const { filters } = this.state;
    //     await this.updatePage(page, pageSize, sortColumn, filters);
    // }

    // onSearch = async ( name: string, value: string) => {
    //     const {page, pageSize } = this.state.pagedData;
    //     const {sortColumn, filters } = this.state;
    //     filters.set(name, value);
    //     await this.updatePage(page, pageSize, sortColumn, filters);
    // };

    // updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadRole>, filters : Map<string, string> ) =>
    // {
    //     const pagedData = await RoleDetailsService.GetRoles(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
    //     this.setState({ filters, pagedData, sortColumn });
    // }

    // onDelete = async ( keyValue : any) => {
    //     //await RoleDetailsService.DeleteRole( keyValue);
    //     this.componentDidMount();
    // }

    render(): JSX.Element {
        //const { pagedData, sortColumn } = this.state;
        const { roleId } = this.props.router.params;
        const { role } = this.state;

        return (
            <div>
                <h1>{role.name} Role</h1>
                <HorizontalTabs>
                    <Tab label="Users">
                        <RoleUsers role={role}/>
                    </Tab>
                    <Tab label="Privileges">
                        <RolePrivileges role={role}/>
                    </Tab>
                </HorizontalTabs>
                {/* <div>
                    <Button className="me-1" buttonType={ButtonType.primary} to="add">Add</Button>
                </div>
                <hr/> */}
                {/* <RolesTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/> */}
            </div>
        );
    }
};

const RoleDetails = withRouter(LocRoleDetails);


export default RoleDetails;
