import Joi from "joi";
import { Navigate } from "react-router-dom";
import Form, { FormError, FormState } from "../../components/common/Form";
import { MakeGeneralIdRef } from "../../utils/GeneralIdRef";
import withRouter, { RouterProps } from "../../utils/withRouter";
import instrumentService, { ReadInstrument } from "../../services/instrumentService";
import { Paginated } from "../../services/Paginated";
import UserInstrumentsTable, { UserInstruments } from "./UserInstrumentsTable";
import userInstrumentService, { GetUserInstrument } from "../../services/userInstrumentService";

interface UserInstrumentsTabProps extends RouterProps {
    isEditMode : boolean;
}

interface UserInstrumentsTabState extends FormState {
    data: {
       instrumentList : Paginated<ReadInstrument>;
       userInstrumentList : Paginated<GetUserInstrument>;
       pagedData : Paginated<UserInstruments>
    };
    redirect: string;
}

class LocUserInstrumentsTab extends Form<UserInstrumentsTabProps, any, UserInstrumentsTabState> {
    state: UserInstrumentsTabState = {
        data: {
            instrumentList: {count: 0, pageSize: 0, page:0, totalPages:0, data: []},
            userInstrumentList : {count: 0, pageSize: 0, page:0, totalPages:0, data: []},
            pagedData : {count: 0, pageSize: 0, page:0, totalPages:0, data: []}
        },
        errors: {},
        redirect: "",
    };
   
    schema = {
        instrumentList: Joi.object(),
        userInstrumentList: Joi.object()
    };

    userId = () => {
        const { userId } = this.props.router.params;
        return userId;
    }

    doMount = async () => {
        const instrumentList = await instrumentService.GetInstruments(0,10,"name",true);
    
        const userInstrumentFilters : Map<string, string> = new Map<string, string>();
        userInstrumentFilters.set( "userId", String(this.userId()));

        const userInstrumentList = await userInstrumentService.getUserInstruments(0,10,"name",true,userInstrumentFilters)

        const pagedData = this.compliePagedData( instrumentList, userInstrumentList );

        const { data } = this.state;

        data.instrumentList = instrumentList;
        data.userInstrumentList = userInstrumentList;
        data.pagedData = pagedData;

        this.setState({ data });
    };

    compliePagedData = ( masterList : Paginated<ReadInstrument>, selectList : Paginated<GetUserInstrument> ) : Paginated<UserInstruments> => {
        return {
            count: masterList.count, 
            pageSize: masterList.pageSize,
            page:masterList.page,
            totalPages:masterList.totalPages,
            data: masterList.data.map( x => {
                const selected : boolean =  this.isSelected(selectList, x );

                const UserInstruments : UserInstruments = {
                    id: x.id,
                    guid: x.guid,
                    name: x.name,
                    selected: selected
                }
                
                return UserInstruments;
            } )
        };
    }

    isSelected = ( selectList: Paginated<GetUserInstrument>, instrument : ReadInstrument ) => {        
        return selectList.data.filter( x => x.instrumentId.id === instrument.id).length > 0;
    }


    handleSave = async ( additions : UserInstruments[], deletions : UserInstruments[] ) => {        
        const userGenerealIdRef = MakeGeneralIdRef(this.userId());

        additions.forEach(async (addition) => {
            const instrumentGeneralIdRef = MakeGeneralIdRef(addition.id, addition.guid );
            await userInstrumentService.postUserInstrument(userGenerealIdRef, instrumentGeneralIdRef);
        });

        deletions.forEach(async (deletion) => {
            const instrumentGeneralIdRef = MakeGeneralIdRef(deletion.id, deletion.guid);
            await userInstrumentService.deleteUserInstrument(userGenerealIdRef, instrumentGeneralIdRef);
        });
        
        await this.doMount();
    }


    render() {
        const { isEditMode } = this.props;

        const { redirect, data } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;
        
        return (
            <form className="editUser" onSubmit={this.handleSubmit}>
                {this.renderError("_general")}
                <UserInstrumentsTable data={data.pagedData!} onSave={this.handleSave}/>
            </form>
        );
    }
}

const UserInstrumentsTab = withRouter(LocUserInstrumentsTab);

export default UserInstrumentsTab;
