import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import { GeneralIdRef, MakeGeneralIdRef } from "../utils/GeneralIdRef";
import partInstrumentService, { CreatePartInstrument } from "../services/partInstrumentService";
import { getParentPath } from "../utils/getParentPath";

interface PartInstrumentDetailsState extends FormState {
    data: {
        instrument?: GeneralIdRef;
    };
    redirect: string;
}

class LocPartInstrumentDetails extends Form<any, any, PartInstrumentDetailsState> {
    state: PartInstrumentDetailsState = {
        data: {
            instrument: undefined
        },
        errors: {},
        redirect: "",
        delayValidation: true
    };

    labelInstrument = "Instrument";
    
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        instrument: Joi.object({
                        id: Joi.optional(),
                        guid: Joi.optional()
                    }).required()            
    };

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    PieceId = () : bigint => {
        const { pieceId } = this.props.router.params;
        return pieceId;
    }

    PartId = () : bigint => {
        const { partId } = this.props.router.params;
        return partId;
    }

    InstrumentId = () : bigint => {
        const { instrumentId } = this.props.router.params;
        return instrumentId;
    }

    doSubmit = async (buttonName : string) => {
        try {
            const { instrument } = this.state.data;
            const libraryId = MakeGeneralIdRef(this.LibraryId());

            if (this.isEditMode()) {
                //const pieceId = this.PieceId();

                // const edit : EditLibraryPiece = {
                //     id : pieceId,
                //     name: name,
                //     libraryId : libraryId
                // };

                //await partInstrumentService.PutLibraryPiece(edit);
                //toast.info("Instrument edited");
            } else {
                const create : CreatePartInstrument = {                    
                    instrumentId : instrument!,
                    partId : MakeGeneralIdRef(this.PartId())
                }

                await partInstrumentService.PostPartInstrument(create);
                toast.info("Instrument added to part");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/library/" + this.LibraryId() + "/" + this.PieceId() + "/" + this.PartId() });
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const pieceId = this.PieceId();

        if (pieceId !== undefined) {
            // const loadedData = await libraryPieceService.GetLibraryPiece(pieceId);

            // const { data } = this.state;
            
            // data.name = loadedData.name;
            // data.folderName = loadedData.folderName;
            // data.composer = loadedData.composer;
            // data.arranger = loadedData.arranger;
            // data.reference = loadedData.reference;
            // data.inPad = loadedData.inPad;

            // this.setState({ data });
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Instrument to part</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInstrumentPicker("instrument", this.labelInstrument)}

                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const PartInstrumentDetails = withRouter(LocPartInstrumentDetails);

export default PartInstrumentDetails;
