import { Component } from 'react';
import { Paginated } from '../../services/Paginated';
import Column from './columns';
import TableBody, { AuditParams } from './TableBody';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import debounce from 'lodash.debounce';

export interface PublishedTableProps<T> {
    data: Paginated<T>,
    sortColumn? : Column<T>,
    selectedRow? : T;
    onChangePage? : (page: number, pageSize : number) => {};
    onSort? : (sortColumn : Column<T>) => void;
    onSearch?: ( name: string, value: string) => void;
    canEdit? : ( keyValue : T ) => boolean;
    canDelete?: ( keyValue : T ) => boolean;
    onDelete?: ( keyValue? : T ) => void;
}

interface TableState{
    debouncedOnSearch?: any
}

export interface TableProps<T> extends PublishedTableProps<T>{
    keyName : string;
    columns : Column<T>[];
    editPath? : string;
    onAuditParams?: ( item : unknown ) => AuditParams;
    secondaryAudit? : boolean;
    showHeader? : boolean;
    showFooter? : boolean;
}

class Table<T> extends Component<TableProps<T>, TableState> {
    state : TableState = {
    }

    componentDidMount(): void {
        const {onSearch } = this.props;        
        const debounceDelay = 200;
        const debouncedOnSearch = onSearch === undefined ? undefined : debounce(onSearch, debounceDelay);

        this.setState( {
            debouncedOnSearch,
        })
    }

    render() {
        const { data, keyName, selectedRow, columns, sortColumn, editPath, onSort, onChangePage, canEdit, canDelete, onDelete, onAuditParams, secondaryAudit, showHeader, showFooter } = this.props;
        const { debouncedOnSearch } = this.state;

        const showEdit = (editPath != null) && (editPath !== "");
        const showDelete = onDelete != null;
        const showAudit = onAuditParams != null;
        const showSecondaryAudit = showAudit && secondaryAudit === true;
                
        const calcShowHeater = showHeader === undefined ? true : showHeader;
        const calcShowFooter = showFooter === undefined ? true : showFooter; 

        return (
            <>
                <table className='table table-sm table-striped table-bordered'>
                    {calcShowHeater &&<TableHeader columns={columns} sortColumn={sortColumn} showDelete={showDelete} showEdit={showEdit} showAudit={showAudit} showSecondaryAudit={showSecondaryAudit} onSort={onSort} onSearch={debouncedOnSearch} />}
                    <TableBody data={data.data} keyName={keyName} selectedRow={selectedRow} columns={columns} canEdit={canEdit} canDelete={canDelete} onDelete={onDelete} editPath={editPath} onAuditParams={onAuditParams} showSecondaryAudit={showSecondaryAudit}/>
                    {calcShowFooter && <TableFooter data={data} columns={columns} showDelete={showDelete} showEdit={showEdit} showAudit={showAudit} showSecondaryAudit={showSecondaryAudit} onChangePage={onChangePage} />}
                </table>                
            </>
        );
    }
}

export default Table;