import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadPartInstrument } from "../../services/partInstrumentService";
import authenticationService from "../../services/authenticationService";

class PartInstrumentsTable extends React.Component<PublishedTableProps<ReadPartInstrument>> {
    columns : Column<ReadPartInstrument>[] = [
        { key: "instrumentName", label: "Instrument Name", order: "asc" },
    ];

    raiseSort = (sortColumn : Column<ReadPartInstrument>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const doDelete = authenticationService.hasAccess("DeleteInstrumentFromPart") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default PartInstrumentsTable;