import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../../components/common/Form";
import { InputType } from "../../components/common/Input";
import { MakeGeneralIdRef } from "../../utils/GeneralIdRef";
import withRouter, { RouterProps } from "../../utils/withRouter";
import userService from "../../services/usersService";
import { getParentPath } from "../../utils/getParentPath";

interface GeneralTabProps extends RouterProps {
    isEditMode : boolean;
}

interface GeneralTabState extends FormState {
    data: {
        emailConfirmed: boolean;
        firstName: string;
        lastName : string,
        middleNames: string,
        email: string,
        newPassword: string,
        confirmPassword: string,
    };
    redirect: string;
}

class LocGeneralTab extends Form<GeneralTabProps, any, GeneralTabState> {
    state: GeneralTabState = {
        data: {
            firstName: "",
            lastName : "",
            middleNames: "",
            email: "",
            newPassword: "",
            confirmPassword: "",
            emailConfirmed: false
        },
        errors: {},
        redirect: "",
    };

    labelFirstName = "First name";
    labelMiddleNames = "Middle names";
    labelLastName = "Last name";
    labelEmail = "Mail";
    labelDomain = "Domain";
    labelNewPassword = "New Password";
    labelConfirmPassword = "Confirm Password";
    labelEmailConfirmed = "Email Confirmed";

    labelApply = "Save";
    labelSave = "Save and close";
    

    schema = {
        firstName: Joi.string().required().max(450).label(this.labelFirstName),
        middleNames: Joi.string().allow("").required().label(this.labelMiddleNames),
        lastName: Joi.string().required().label(this.labelLastName),
        email: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label(this.labelEmail),
        domain: Joi.optional(),
        newPassword: Joi.string().allow("").min(5).label(this.labelNewPassword),
        confirmPassword: Joi.string()
            .when("newPassword", {
                is: "",
                then: Joi.allow("").optional(),
                otherwise: Joi.valid(Joi.ref("newPassword")).error(() => {
                    const e = new Error("Passwords must match");
                    e.name = "confirmPassword";
                    return e;
                }),
            })
            .label(this.labelConfirmPassword),
        emailConfirmed: Joi.boolean().label(this.labelEmailConfirmed)
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { isEditMode } = this.props;

            const { firstName, middleNames, lastName, email, newPassword, emailConfirmed } = this.state.data;

            if (isEditMode) {
                const { userId } = this.props.router.params;

                var generalIdRef = MakeGeneralIdRef(userId);
                await userService.putUser(generalIdRef, firstName, middleNames, lastName, email, newPassword, emailConfirmed);
                toast.info("User edited");
            } else {
                await userService.postUser(firstName, middleNames, lastName, email);
                toast.info("New User added");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/users" });
        }
        catch (ex: any) {
            this.handleGeneralError(ex)
        }
    };

    doMount = async () => {
        const { userId } = this.props.router.params;

        const { data } = this.state;

        if (userId !== undefined) {
            try
            {
                const loadedData = await userService.getUser(userId);

                data.firstName = loadedData.firstName;
                data.lastName = loadedData.lastName;
                data.middleNames = loadedData.middleNames;
                data.email = loadedData.email;   
                data.newPassword = "";
                data.confirmPassword = "";
                data.emailConfirmed = loadedData.emailConfirmed;
            }
            catch (ex: any) {
                this.handleFatalError(ex)
            }
        }

        this.setState({ data });
    };

    render() {
        const link = this.props.router.location.pathname;
        const { isEditMode } = this.props;
        
        const parent = getParentPath(link);
        
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;
        
        return (
            <form className="editUser" onSubmit={this.handleSubmit}>
                {this.renderError("_general")}
                {isEditMode && this.renderInput("email", this.labelEmail, InputType.text, true)}
                {!isEditMode && this.renderInput("email", this.labelEmail)}
                {this.renderInput("firstName", this.labelFirstName)}
                {this.renderInput("middleNames", this.labelMiddleNames)}
                {this.renderInput("lastName", this.labelLastName)}

                {this.renderDropSection("resetPassword", <>Reset Password</>, <>
                    {this.renderInput("newPassword", this.labelNewPassword, InputType.password)}
                    {this.renderInput("confirmPassword", this.labelConfirmPassword, InputType.password)}
                </>)}
                {this.renderToggle("emailConfirmed", this.labelEmailConfirmed)}

                {this.renderLink("Cancel",undefined,parent)}
                {isEditMode && this.renderButton(this.labelApply)}
                {this.renderButton(this.labelSave)}
            </form>
        );
    }
}

const GeneralTab = withRouter(LocGeneralTab);

export default GeneralTab;
