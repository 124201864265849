
const storedTheme = localStorage.getItem('theme')
  
const getPreferredTheme = () => {
    if (storedTheme) {
        return storedTheme
    }
  
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

const setTheme = function (theme: string) {
    localStorage.setItem('theme', theme)
}

const theme = {
    getPreferredTheme,
    setTheme
}

export default theme