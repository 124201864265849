import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from './classicEditor';

class TextEditor extends React.Component{
    state = {
        loaded: false
    }

    async componentDidMount() {
        this.setState({ loaded: true });
    }

    render() {
        const { name,  data, onChange } = this.props;
        const { loaded } = this.state;
        
        if (!loaded){
            return <div>Loading</div>
        }

        const editor = ClassicEditor;

        return (
            <CKEditor
            editor={ editor }
            data={data}            

            onReady={ editor => {
                // You can store the "editor" and use when it is needed.
                //console.log( 'Editor is ready to use!', editor );
            } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();

                onChange( name, data );
                //console.log( { event, editor, data } );
            } }
            onBlur={ ( event, editor ) => {
                //console.log( 'Blur.', editor );
            } }
            onFocus={ ( event, editor ) => {
                //console.log( 'Focus.', editor );
            } }            
            /> 
        )
    }
}


export default TextEditor


