import httpService from "./httpService";
import { Paginated } from "./Paginated";
import { GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";

const apiEndpoint = "/UserInstrument";

export interface IUserInstrumentBase {
    instrumentId : GeneralIdRef,
    userId : GeneralIdRef
}

export interface GetUserInstrument extends IUserInstrumentBase{
    
}

export async function getUserInstruments( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<GetUserInstrument>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<GetUserInstrument>>(apiEndpoint + "/userInstruments", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function postUserInstrument(userId : GeneralIdRef, instrumentId : GeneralIdRef): Promise<void>{
    await httpService.post(apiEndpoint + "/userInstrument", {
        instrumentId,
        userId
    });
}

export async function deleteUserInstrument( userId : GeneralIdRef, instrumentId : GeneralIdRef): Promise<void>{
    await httpService.delete(apiEndpoint + "/userInstrument", {
        data:{ 
            instrumentId,
            userId
        }
    } );
}

const userInstrumentService = {
    getUserInstruments,
    postUserInstrument,
    deleteUserInstrument
};

export default userInstrumentService;
