import React from "react";
import TextEditor from "./ckeditor/TextEditor";

interface TemplateEditorProps {
    name : string;
    data : string;
    showFields : boolean;
    onChange : ( name : string, value : string ) => void;
}
 
interface TemplateEditorState {
    
}

class TemplateEditor extends React.Component<TemplateEditorProps, TemplateEditorState> {
    //state = { :  }

    render() { 
        const { name,  data, showFields, onChange } = this.props;

        return ( <TextEditor name={name} data={data} onChange={onChange} showFields={showFields} />
        );
    }
}
 
export default TemplateEditor;