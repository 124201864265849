import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import Button, { ButtonType } from '../components/common/Button';
import InstrumentTable from './components/instrumentTable';
import instrumentService, { ReadInstrument } from '../services/instrumentService';
import { toast } from 'react-toastify';
import Permission from '../components/common/Permission';

interface InstrumentsState{
    pagedData : Paginated<ReadInstrument>,
    sortColumn : Column<ReadInstrument>,
    filters: Map<string, string>;
}

class Instruments extends Component< any, any, InstrumentsState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;       
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadInstrument>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadInstrument>, filters : Map<string, string> ) =>
    {
        const pagedData = await instrumentService.GetInstruments(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadInstrument) => {
        await instrumentService.DeleteInstrument( keyValue?.id, keyValue?.guid);

        toast.success( "Instrument " + keyValue?.name + " deleted");
        this.componentDidMount();
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="CreateInstrument">
                    <div>
                        <Button buttonType={ButtonType.primary} to="add">Add</Button>
                    </div>
                    <hr/>
                </Permission>
                <InstrumentTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

export default Instruments;
