import { GeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "PartInstrument";

interface LibraryPartBase{
    instrumentId : GeneralIdRef,
    partId : GeneralIdRef
}

export interface CreatePartInstrument extends LibraryPartBase{

}

export interface DelPartInstrument extends LibraryPartBase{

}

export interface ReadPartInstrument extends LibraryPartBase{
    instrumentName : string;
    partName : string;
}

export async function GetPartInstruments( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadPartInstrument>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadPartInstrument>>(apiEndpoint + "/partInstruments", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function PostPartInstrument( partInstrument : CreatePartInstrument): Promise<void>{
    await httpService.post(apiEndpoint + "/partInstrument", partInstrument);
}

export async function DeletePartInstrument( partInstrument : DelPartInstrument): Promise<void>{
    await httpService.delete(apiEndpoint + "/partInstrument", { data: partInstrument });
}

export async function AutoPopulate( id? : bigint, guid?: string): Promise<void>{
    const params = MakeGeneralIdRefParams(id, guid);

    await httpService.post(apiEndpoint + "/autoPopulate?" + params );
}

const partInstrumentService = {
    GetPartInstruments,
    PostPartInstrument,
    DeletePartInstrument,
    AutoPopulate
};

export default partInstrumentService;
