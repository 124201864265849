import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import { MakeGeneralIdRef } from "../utils/GeneralIdRef";
import libraryPartService, { CreateLibraryPart, EditLibraryPart } from "../services/libraryPartService";
import { getParentPath } from "../utils/getParentPath";

interface LibraryPartDetailsState extends FormState {
    data: {
        name: string;
        fileName: string;        
    };
    redirect: string;
}

class LocLibraryPartDetails extends Form<any, any, LibraryPartDetailsState> {
    state: LibraryPartDetailsState = {
        data: {
            name: "",
            fileName: ""
        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    labelFileName = "Filename";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName),
        fileName: Joi.string().required().max(450).label(this.labelFileName)
    };

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    PieceId = () : bigint => {
        const { pieceId } = this.props.router.params;
        return pieceId;
    }

    PartId = () : bigint => {
        const { partId } = this.props.router.params;
        return partId;
    }

    doSubmit = async (buttonName : string) => {
        try {
            const { name, fileName } = this.state.data;
            const pieceId = MakeGeneralIdRef(this.PieceId());

            if (this.isEditMode()) {
                const edit : EditLibraryPart = {
                    id : this.PartId(),
                    name: name,
                    fileName: fileName,
                    pieceId: pieceId
                };

                await libraryPartService.PutLibraryPart(edit);
                toast.info("Part edited");
            } else {
                const create : CreateLibraryPart = {                    
                    name: name,
                    fileName: fileName,
                    pieceId: pieceId
                }

                await libraryPartService.PostLibraryPart(create);
                toast.info("New Part added");
            }

            if (buttonName === this.labelSave)            
                this.setState({ redirect: "/library/" + this.LibraryId() + "/" + this.PieceId() });
        }
        catch (ex: any) {
            this.handleGeneralError(ex)
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const partId = this.PartId();

        if (partId !== undefined) {
            try
            {
                const loadedData = await libraryPartService.GetLibraryPart(partId);

                const { data } = this.state;
            
                data.name = loadedData.name;
                data.fileName = loadedData.fileName

                this.setState({ data });
            }
            catch (ex: any) {
                this.handleFatalError(ex)
            }
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Part</h1>
                <form className="editPart" onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName)}
                    {this.renderInput("fileName", this.labelFileName)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const LibraryPartDetails = withRouter(LocLibraryPartDetails);

export default LibraryPartDetails;
