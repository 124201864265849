import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { isValid, parseISO } from "date-fns";

Object.defineProperty(BigInt.prototype, "toJSON", {
    get() {
        return () => Number(this);
    }
});

const onRequest = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    // console.info(`[request] [${JSON.stringify(config)}]`);
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    // console.error(`[request error] [${JSON.stringify(error)}]`);
    return Promise.reject(error);
};

export function handleDates(body: any) {
    if (body === null || body === undefined || typeof body !== "object")
      return body;
  
    for (const key of Object.keys(body)) {
      const value = body[key];
      const parsedValue : Date = parseISO(value);
      if (isValid(parsedValue)) body[key] = parsedValue;
      else if (typeof value === "object") handleDates(value);
    }
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    handleDates(response.data);
    return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    // console.error(`[response error] [${JSON.stringify(error)}]`);

    // const expectedError = error.response && error.response.status >= 400 && error.response.status < 500;
    // if (!expectedError) {
    //     toast.error("An unexpected error occured.");
    // }

    return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}

axios.defaults.baseURL = window.__RUNTIME_CONFIG__.API_URL;
setupInterceptorsTo(axios);


export async function setJwt(jwt: string | null) {
    if (jwt) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
}

export function baseUrl(){
    return axios.defaults.baseURL;
}

const httpService = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    baseUrl,
    setJwt,
};

export default httpService;
