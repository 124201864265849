export type Paginated<T> = {
    count: number,
    pageSize : number,
    page: number,
    totalPages : number,
    data : T[];
}

export function AddPagination<T>( items : T[] ) : Paginated<T>
{
    const paginated : Paginated<T> =
    {
        page: 1,
        pageSize: 10,
        totalPages: 1,
        data: items,
        count : items.length
    };

    return paginated;
}