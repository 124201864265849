import React from "react";
import HorizontalTabs from "../components/common/HorizionalTabs";
import Tab from "../components/common/Tab";
import GeneralTab from "./components/GeneralTab";
import UserInstrumentsTab from "./components/UserInstrumentsTab";
import UserRolesTab from "./components/UserRolesTab";

interface UserDetailsProps {
    editMode : boolean;
}

class UserDetails extends React.Component<UserDetailsProps> {
    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    render() {
        const isEditMode = this.isEditMode();

        let mode = "Add";
        if (isEditMode) mode = "Edit";


        let tabs : JSX.Element[] = [];

        tabs.push( <Tab key={1} label="General">
                <GeneralTab isEditMode={isEditMode}/>
            </Tab> );

        if (isEditMode){
            tabs.push( <Tab key={1} label="Instruments">
                <UserInstrumentsTab isEditMode={isEditMode}/>
            </Tab> );

            tabs.push( <Tab key={1} label="Security Roles">
                <UserRolesTab isEditMode={isEditMode}/>
            </Tab> );
        }

        return (
            <div>
                <h1>{mode} User</h1>
                <HorizontalTabs>
                    {tabs}
                </HorizontalTabs>
            </div>
        );
    }
}

export default UserDetails;
