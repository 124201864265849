import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadLibraryPiece } from "../../services/libraryPieceService";
import authenticationService from "../../services/authenticationService";

class LibraryPiecesTable extends React.Component<PublishedTableProps<ReadLibraryPiece>> {
    columns : Column<ReadLibraryPiece>[] = [
        { key: "name", label: "Name", order: "asc", link:"{0}" },
        { key: "folderName", label: "FolderName", order: "asc" },
        { key: "arranger", label: "Arranger", order: "asc" },
        { key: "composer", label: "Composer", order: "asc" },
        { key: "reference", label: "Reference", order: "asc" },
        { key: "inPad", label: "In Pad", order: "asc", searchable:false, content: (item) => {return (<>{String(item!.inPad)}</>)} }
    ];

    raiseSort = (sortColumn : Column<ReadLibraryPiece>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const editPath = authenticationService.hasAccess("EditPiece") ? "{0}/edit" : undefined
        const doDelete = authenticationService.hasAccess("DeletePiece") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default LibraryPiecesTable;