import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import authentication from "../services/authenticationService";
import libraryService, { ReadLibrary } from "../services/libraryService";
import Logo from "./components/Logo";
import authenticationService from "../services/authenticationService";

interface MainMenuProps {
    
}
 
interface MainMenuState {
    libraries : ReadLibrary[];
}
 
class MainMenu extends React.Component<MainMenuProps, MainMenuState> {
    state : MainMenuState = {
        libraries: []
    }

    componentDidMount = async() => {
        var result = await libraryService.GetLibraries(0, 10, "name", true);
        this.setState({libraries: result.data});
    }

    render() { 
        //const myAccess : GetSecurityAccess[] = securityService.GetMyAccess();
        //console.log("testing", myAccess);

        const user = authentication.getCurrentUser();
        const { libraries } = this.state;       

        const viewUsers = authenticationService.hasAccess( "ViewUser" );
        const viewLibrary = authenticationService.hasAccess( "ViewLibrary" );
        const viewInstrument = authenticationService.hasAccess( "ViewInstrument" );
        const viewSecurity = authenticationService.hasAccess( "ViewSecurity" );
        const viewPerformance = authenticationService.hasAccess( "ViewPerformance" );
    
        const viewAdmin = viewUsers || viewLibrary || viewInstrument || viewSecurity || viewPerformance;

        return (
            <Navbar expand="lg">
                <Navbar.Brand href="/">
                    <div>
                        <Logo height="80vh"/>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <NavDropdown title="Library">                        
                            {libraries.map( x => {
                                if (x.published){
                                    return (<Nav.Link key={x.guid} href={"/viewLibrary/" + x.id}>{x.name}</Nav.Link>)
                                }
                            })}
    
                        </NavDropdown>
                        {viewAdmin && <NavDropdown title="Admin">
                            {viewUsers && <Nav.Link href="/users">Users</Nav.Link>}
                            {viewLibrary && <Nav.Link href="/library">Libraries</Nav.Link>}
                            {viewInstrument && <Nav.Link href="/instrument">Instruments</Nav.Link>}
                            {viewSecurity && <Nav.Link href="/security">Security</Nav.Link>}
                            {viewPerformance && <Nav.Link href="/performance">Performance</Nav.Link>}
                        </NavDropdown>}
                        {/* <Nav.Link href="/audit">Audit Log</Nav.Link>  */}
                        <Nav.Link href="/profile">Profile: {user?.displayName}</Nav.Link>
                        <Nav.Link href="/logout">Logout</Nav.Link>
                        <Nav.Link href="https://drive.google.com/file/d/1dPwp08rIKUiazoSrV-VaSyTrksZCAtf1/view" target="_blank">User Guide</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}
 
export default MainMenu;
