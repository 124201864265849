import React, { Component } from "react";
import { Paginated } from "../../services/Paginated";
import Column from "./columns";
import Pagination from "./Pagination";

export interface TableFooterProps<T>{
    data : Paginated<T>;
    columns : Column<T>[];
    showEdit : boolean;
    showDelete : boolean;    
    showAudit : boolean;
    showSecondaryAudit : boolean;
    onChangePage? : (page: number, pageSize: number) => void;
}

class TableFooter<T> extends Component<TableFooterProps<T>> {
    render() {
        const { data, columns, showEdit, showDelete, showAudit, showSecondaryAudit, onChangePage} = this.props;
        
        let staticColumnCount = 0;
        if (showEdit) staticColumnCount++;
        if (showDelete) staticColumnCount++;
        if (showAudit) staticColumnCount++;
        if (showAudit && showSecondaryAudit) staticColumnCount++;       
        
        let pagination = onChangePage === undefined ? undefined : <Pagination data={data} onChangePage={onChangePage}/>;

        if (pagination)
            return <tfoot>
                        <tr>
                            <td colSpan={columns.length+staticColumnCount}>{pagination}</td>
                        </tr>
                    </tfoot>

        return <></>
    }
}

export default TableFooter;
