import { FunctionComponent } from "react";
import theme from "../../utils/theme";
import lightLogo from "../../components/images/BCBLogoPrint.svg";
import darkLogo from "../../components/images/BCBLogoPrintWhiteText.svg";

interface LogoProps {
    height? : string
}
 
const Logo: FunctionComponent<LogoProps> = (props:LogoProps) => {
    const preferredTheme = theme.getPreferredTheme();
    const logo = preferredTheme === "dark" ? darkLogo : lightLogo;   

    return ( <img height={props.height} src={logo}/>);
}
 
export default Logo;