import Form, { FormState, FormData, businessValidationResult } from "../components/common/Form";
import Joi from "joi";
import authentication from "../services/authenticationService";
import { IEmailUserAction } from "../models/IEmailUserAction";
import { InputType } from "../components/common/Input";
import Button, { ButtonType } from "../components/common/Button";

export interface EmailUserActionPasswordResetData extends FormData {
    password: string;
    confirmPassword: string;
    passwordChanged: boolean;
}

export interface EmailUserActionPasswordResetState extends FormState {
    data: EmailUserActionPasswordResetData;
}

class EmailUserActionPasswordReset extends Form<any, any, EmailUserActionPasswordResetState> {
    state = {
        data: { password: "", confirmPassword: "", passwordChanged: false },
        errors: {},
    };

    labelPassword = "Password";
    labelConfirmPassword = "Confirm Password";
    labelChangePassword = "Change Password";

    schema = {
        password: Joi.string().required().min(5).label(this.labelPassword),
        confirmPassword: Joi.string()
            .when("password", {
                is: "",
                then: Joi.optional(),
                otherwise: Joi.valid(Joi.ref("password")).error(() => {
                    const e = new Error("Passwords must match");
                    e.name = "confirmPassword";
                    return e;
                }),
            })
            .label(this.labelConfirmPassword),

        passwordChanged: Joi.boolean(),
    };

    BusinessValidation(): businessValidationResult | null {
        const { password, confirmPassword } = this.state.data;

        if (password !== confirmPassword) {
            return {
                details: [
                    {
                        path: "confirmPassword",
                        message: "You need to confirm by typing exactly the same as the new password",
                    },
                ],
            };
        }

        return null;
    }

    doSubmit = async (buttonName : string) => {
        const { emailUserAction } = this.props;
        const { password } = this.state.data;

        const action: IEmailUserAction = {
            email: emailUserAction.email,
            token: emailUserAction.token,
            password: password,
            emailActionType: emailUserAction.emailActionType,
        };

        try {
            const callResult = await authentication.completeEmailAction(action);
            if (callResult === 1) {
                let data = { ...this.state.data };
                data.passwordChanged = true;
                this.setState({ data });
            }
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    render() {
        const { passwordChanged } = this.state.data;

        if (passwordChanged) {
            return (
                <>
                    <div>Your password has been changed.</div>
                    <Button buttonType={ButtonType.link} to="/">Login</Button>   
                </>
            )
        }

        return (
            <>
                <div>Reset password</div>

                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("password", this.labelPassword, InputType.password)}
                    {this.renderInput("confirmPassword", this.labelConfirmPassword, InputType.password)}
                    {this.renderButton(this.labelChangePassword)}
                </form>
            </>
        );
    }
}

export default EmailUserActionPasswordReset;
