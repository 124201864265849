import React from "react";

interface ProgressBarProps {
    text : string;
    percent : number;    
}
 
interface ProgressBarState {

}
 
class ProgressBar extends React.Component<ProgressBarProps, ProgressBarState> {
        state = { }
    render() { 
        const { text, percent } = this.props;

        const roundedPercent = Number(percent.toFixed(0));

        const progressStyle = {
            width: roundedPercent + "%"
        }

        return ( <span>{text}
                <span className="progress"> <span className="progress-bar" style={progressStyle} role="progressbar" aria-valuenow={roundedPercent} aria-valuemin={0} aria-valuemax={100}> {roundedPercent + "%"}</span>
            </span> 
        </span> );
    }
}
 
export default ProgressBar; 