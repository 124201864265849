import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import libraryPieceService, { CreateLibraryPiece, EditLibraryPiece } from "../services/libraryPieceService";
import { InputType } from "../components/common/Input";
import { MakeGeneralIdRef } from "../utils/GeneralIdRef";
import { getParentPath } from "../utils/getParentPath";

interface LibraryPieceDetailsState extends FormState {
    data: {
        name: string;
        folderName: string;        
        composer: string,
        arranger: string,
        reference: string;
        category: string,
        notes: string,
        duration: string,
        inPad: boolean;
    };
    redirect: string;
}

class LocLibraryPieceDetails extends Form<any, any, LibraryPieceDetailsState> {
    state: LibraryPieceDetailsState = {
        data: {
            name: "",
            folderName: "",
            composer: "",
            arranger: "",
            reference: "",
            category: "",
            duration: "00:00:00",
            notes: "",
            inPad: false,
        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    labelFolderName = "Folder Name";
    labelPublished = "Published";

    labelComposer = "Composer";
    labelArranger = "Arranger";
    labelReference = "Reference";
    labelCategory = "Category";
    labelNotes = "Notes";
    labelInPad = "In Pad";
    labelDuration = "Duration";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName),
        folderName: Joi.string().required().max(450).label(this.labelFolderName),
        composer: Joi.string().required().allow("").max(450).label(this.labelComposer),
        arranger: Joi.string().required().allow("").max(450).label(this.labelArranger),
        reference: Joi.string().required().allow("").max(450).label(this.labelReference),
        category: Joi.string().required().allow("").max(450).label(this.labelCategory),
        notes: Joi.string().required().allow("").label(this.labelNotes),
        inPad: Joi.boolean().required().label(this.labelInPad),
        duration: Joi.string().required().regex(/^[0-9]{2}:[0-5][0-9]:[0-5][0-9](\.[0-9]{1,3})?$/, "hh:mm:ss").label(this.labelDuration),
    };

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    PieceId = () : bigint => {
        const { pieceId } = this.props.router.params;
        return pieceId;
    }

    doSubmit = async (buttonName : string) => {
        try {
            const { name, folderName, composer, arranger, reference, inPad, notes, category, duration } = this.state.data;
            const libraryId = MakeGeneralIdRef(this.LibraryId());

            if (this.isEditMode()) {
                const pieceId = this.PieceId();

                const edit : EditLibraryPiece = {
                    id : pieceId,
                    name: name,
                    folderName: folderName,
                    composer: composer,
                    arranger: arranger,
                    reference: reference,
                    inPad: inPad,
                    notes: notes,
                    category: category,
                    duration: duration,
                    libraryId : libraryId
                };

                await libraryPieceService.PutLibraryPiece(edit);
                toast.info("Piece edited");
            } else {
                const create : CreateLibraryPiece = {                    
                    name: name,
                    folderName: folderName,
                    composer: composer,
                    arranger: arranger,
                    reference: reference,
                    notes: notes,
                    category: category,
                    duration: duration,
                    inPad: inPad,
                    libraryId : libraryId
                }

                await libraryPieceService.PostLibraryPiece(create);
                toast.info("New Piece added");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "libraryPieces/" + this.LibraryId() });
        }
        catch (ex: any) {
            this.handleGeneralError(ex)
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const pieceId = this.PieceId();

        if (pieceId !== undefined) {
            try {
                const loadedData = await libraryPieceService.GetLibraryPiece(pieceId);

                const { data } = this.state;
            
                data.name = loadedData.name;
                data.folderName = loadedData.folderName;
                data.composer = loadedData.composer;
                data.arranger = loadedData.arranger;
                data.reference = loadedData.reference;
                data.notes = loadedData.notes;
                data.category = loadedData.category;
                data.duration = loadedData.duration;
                data.inPad = loadedData.inPad;

                this.setState({ data });
            }
            catch (ex: any) {
                this.handleFatalError(ex)
            }
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;
        
        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Piece</h1>
                <form className="editPiece" onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName)}
                    {this.renderInput("folderName", this.labelFolderName)}
                    {this.renderInput("composer", this.labelComposer)}
                    {this.renderInput("arranger", this.labelArranger)}
                    {this.renderInput("category", this.labelCategory)}
                    {this.renderInput("reference", this.labelReference)}
                    {this.renderInput("inPad", this.labelInPad, InputType.checkbox)}
                    {this.renderInput("notes", this.labelNotes)}
                    {this.renderInput("duration", this.labelDuration)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const LibraryPieceDetails = withRouter(LocLibraryPieceDetails);

export default LibraryPieceDetails;
