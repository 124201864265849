import React from "react";
import Select, { Option } from "./../common/Select";
import { GeneralIdRef } from "./../../utils/GeneralIdRef";
import securityService from "../../services/securityService";

interface RolePickerProps {
    name: string;
    label: string;
    error?: string;
    value: any;
    domain? : GeneralIdRef;
    onChange?: (name: string, value: GeneralIdRef) => void;
}

interface RolePickerState {
    options?: Option[];
}

class RolePicker extends React.Component<RolePickerProps, RolePickerState> {
    state = { options: undefined };

    async componentDidMount() {
        const pagedData = await securityService.GetRoles(0, 10, "name", true);

        const options: Option[] = (pagedData.data as any[]).map(x => {
            return {
                _id: x.id,
                name: x.name,
            };
        });

        this.setState({ options });
    }

    handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { onChange } = this.props;
        const input = e.currentTarget;

        const generalIdRef: GeneralIdRef = {
            id: BigInt(input.value),
        };

        if (onChange) onChange(input.name, generalIdRef);
    };

    render() {
        const { name, label, error, value } = this.props;
        const { options } = this.state;

        return (
            <Select name={name} label={label} error={error} value={String(value?.id)} options={options} includeBlankFirstEntry={true} onChange={this.handleChange} />
        );
    }
}

export default RolePicker;
