import React from "react";
import Logo from "./components/Logo";
import './LoggedOutFrame.css';

interface LoggedOutFrameProps {
    children? : JSX.Element    
}
 
interface LoggedOutFrameState {
    
}
 
class LoggedOutFrame extends React.Component<LoggedOutFrameProps, LoggedOutFrameState> {
    
    render() { 
        const {children} = this.props;

        return ( <div>
                    <div>
                        <Logo />
                        {children}
                    </div>
                </div> );
    }
}
 
export default LoggedOutFrame;