import Joi from "joi";
import Form, { FormData, FormState, FormError } from "../components/common/Form";
import authentication from "../services/authenticationService";

export interface ForgotPasswordData extends FormData {
    username: string;
    emailSent: boolean;
}

export interface ForgotPasswordtate extends FormState {
    data: ForgotPasswordData;
}

class ForgotPassword extends Form<any, any, ForgotPasswordtate> {
    state = {
        data: { username: "", emailSent: false },
        errors: {},
        delayValidation: true
    };

    schema = {
        username: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label("Username"),
        emailSent: Joi.boolean().required(),
    };

    doSubmit = async (buttonName : string) => {
        try {
            let { data } = this.state;

            await authentication.forgotPassword(data.username);

            data.emailSent = true;
            this.setState({ data });
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    render() {
        const { emailSent } = this.state.data;

        let content = (
            <form onSubmit={this.handleSubmit}>
                {this.renderError("_general")}
                {this.renderInput("username", "e-mail")}
                {this.renderButton("Reset password")}
            </form>
        );
        if (emailSent) {
            content = <div>An email with a password reset link has been sent.</div>;
        }

        return (
            <div className="loginForm">
                <h1>Forgot password</h1>
                {content}
            </div>
        );
    }
}

export default ForgotPassword;
