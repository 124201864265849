import { AppendParam, GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";
import authentication from './authenticationService';
import { AxiosProgressEvent } from "axios";

const apiEndpoint = "LibraryPart";

interface LibraryPartBase{
    guid? : string;
    name : string;
    fileName: string;
    pieceId: GeneralIdRef;
}

export interface CreateLibraryPart extends LibraryPartBase{

}

export interface ReadLibraryPart extends LibraryPartBase{
    id? : bigint;
}

export interface EditLibraryPart extends LibraryPartBase{
    id? : bigint;
}

export async function GetLibraryParts( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadLibraryPart>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadLibraryPart>>(apiEndpoint + "/libraryParts", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetLibraryPart( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadLibraryPart>(apiEndpoint + "/libraryPart?" + params );

    return data;
}

export async function PostLibraryPart( library : CreateLibraryPart): Promise<void>{
    await httpService.post(apiEndpoint + "/libraryPart", library);
}

export async function PutLibraryPart( library : EditLibraryPart ): Promise<void>{
    await httpService.put(apiEndpoint + "/libraryPart", library);
}

export async function DeleteLibraryPart( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/libraryPart", { data: generalIdRef });
}

export async function AutoPopulate( id? : bigint, guid?: string): Promise<void>{
    const params = MakeGeneralIdRefParams(id, guid);

    await httpService.post(apiEndpoint + "/autoPopulate?" + params );
}

export async function DownloadFile( part : ReadLibraryPart): Promise<void>{
    const params = MakeGeneralIdRefParams(part.id, part.guid);
    const fileName = part.fileName;

    var response = await httpService.get(apiEndpoint + "/file?" + params, {responseType: 'blob'} );
    const type = response.headers['content-type'];
    
    const blob = new Blob( [ response.data ], {
        type : type
        }
    )

    const link = document.createElement('a');
    try{
        link.href = window.URL.createObjectURL(blob)
        try {
            link.download = fileName;
            link.click();
        }
        finally{
            window.URL.revokeObjectURL(link.href)
        }
    }
    finally {
        document.removeChild(link);
    }
}

export function GetFileUrl(part : ReadLibraryPart){
    const jwt = authentication.getJwt() ?? undefined;

    const params = AppendParam( MakeGeneralIdRefParams(part.id, part.guid), "token", jwt )
    const fileName = part.fileName;

    const downloadUrl = httpService.baseUrl() + apiEndpoint + "/fileWithToken?" + params;

    return {
        fileName,
        downloadUrl
    }
}

export async function UploadFiles( files : File[], id?: bigint, guid?: string, onProgress?: (percent:number) => void )
{
    const onUploadProgress = (event:AxiosProgressEvent) => {
        if (onProgress !== null )
            onProgress!(event.progress! * 100);
      };

    let formData = new FormData()
    files.forEach(file => {
        formData.append("files", file);
    });
    
    if (id !== undefined)
        formData.append("Id", String(id));
    if (guid !== undefined)
        formData.append("Guid", String(guid));

    await httpService.post(apiEndpoint + "/files", formData,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        } 
    );
}

const libraryPartService = {
    GetLibraryParts,
    GetLibraryPart,
    PostLibraryPart,
    PutLibraryPart,
    DeleteLibraryPart,
    AutoPopulate,
    DownloadFile,
    GetFileUrl,
    UploadFiles
};

export default libraryPartService;
