import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import securityService, { CreateRole, CreateUserRole, EditRole } from "../services/securityService";
import { GeneralIdRef, MakeGeneralIdRef } from "../utils/GeneralIdRef";
import { getParentPath } from "../utils/getParentPath";

interface AddRoleUserState extends FormState {
    data: {
        userId?: GeneralIdRef;
    };
    redirect: string;
}

class LocAddRoleUser extends Form<any, any, AddRoleUserState> {
    state: AddRoleUserState = {
        data: {
            userId: {id: BigInt(0)}
        },
        errors: {},
        redirect: "",
    };

    labelUserId = "User";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        userId: Joi.object().required().label(this.labelUserId)
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { roleId } = this.props.router.params;
            const { userId } = this.state.data;

            if (this.isEditMode()) {
                throw new Error("edit mode not implemented");
                // const { roleId } = this.props.router.params;

                // // const editRole : EditRole = {
                // //     id : MakeGeneralIdRef( roleId),
                // //     userId: name,
                // //     isAdministrator: false,
                // //     isSuperUser : false
                // // };

                // // await securityService.PutRole(editRole);
                // toast.info("User added to role edited");
            } else {
                const createUserRole : CreateUserRole = {
                    roleId: MakeGeneralIdRef(BigInt(roleId)),
                    userId: userId!
                }

                await securityService.PostUserRole(createUserRole);
                toast.info("User added to role");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/role/" + roleId });
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        // const { roleId } = this.props.router.params;

        // if (roleId !== undefined) {
        //     // const loadedData = await securityService.GetRole(roleId);

        //     // const { data } = this.state;
            
        //     // data.userId = loadedData.name;

        //     //this.setState({ data });
        // }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link, 2);
        }

        return (
            <div>
                <h1>{mode} User to role</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderUserPicker("userId", this.labelUserId)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const AddRoleUser = withRouter(LocAddRoleUser);

export default AddRoleUser;
