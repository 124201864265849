import { MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "/Instrument";

export interface InstrumentBase{
    guid? : string,
    name : string
}

export interface ReadInstrument extends InstrumentBase{
    id : bigint
}

export interface CreateInstrument extends InstrumentBase{

}

export interface EditInstrument extends InstrumentBase{
    id : bigint
}

export async function GetInstruments( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadInstrument>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadInstrument>>(apiEndpoint + "/instruments", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetInstrument( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadInstrument>(apiEndpoint + "/instrument?" + params );

    return data;
}

export async function PostInstrument( create : CreateInstrument): Promise<void>{
    await httpService.post(apiEndpoint + "/instrument", create);
}

export async function PutInstrument( edit : EditInstrument ): Promise<void>{
    await httpService.put(apiEndpoint + "/instrument", edit);
}

export async function DeleteInstrument( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/instrument", { data: generalIdRef });
}

const instrumentService = {
    GetInstruments,
    GetInstrument,
    PostInstrument,
    PutInstrument,
    DeleteInstrument
};

export default instrumentService;
