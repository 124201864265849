import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadPerformanceReport } from "../../services/diagnosticService";
import { prettyPrintJson } from 'pretty-print-json';
import parse from 'html-react-parser';

class PerformanceReportTable extends React.Component<PublishedTableProps<ReadPerformanceReport>> {
    columns : Column<ReadPerformanceReport>[] = [
        { key: "id", label: "Id", order: "asc"},
        { key: "startDateTime", label: "Start", order: "asc"},
        { key: "totalTimeMS", label: "Time (MS)", order: "asc"},
        { key: "timings", label: "Timings", order: "asc", content: (item) => {
            const pretty = prettyPrintJson.toHtml(JSON.parse(item.timings))
            return <pre>{parse(pretty)}</pre>
        }}
    ];

    raiseSort = (sortColumn : Column<ReadPerformanceReport>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch } = this.props;

        return <Table data={ data } keyName="actionName" columns={this.columns} sortColumn={sortColumn} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch}/>;
    }
}
 
export default PerformanceReportTable;