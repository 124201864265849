import * as React from "react";
import MainMenu from "./MainMenu";

type FrameProps = {
  children?: React.ReactNode; // 👈️ type children
};

export default function Frame(props: FrameProps) {

    return (
      <>
        <MainMenu/>
        <div>{props.children}</div>
      </>
    );
}