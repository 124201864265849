import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadLibraryViewer } from "../../services/libraryViewerService";
import LibraryViewerPartsTable from "./libraryViewerPartsTable";
import { AddPagination } from "../../services/Paginated";
import { ReadLibraryPart } from "../../services/libraryPartService";

interface LibraryViewerTableProps extends PublishedTableProps<ReadLibraryViewer>
{
    onFileDownLoad : ( part? : ReadLibraryPart ) => void;
}

class LibraryViewerTable extends React.Component<LibraryViewerTableProps> {
    columns : Column<ReadLibraryViewer>[] = [
        { key: "piece.name", label: "Piece", order: "asc" },
        { key: "parts.name", label: "Parts", order: "asc", content: (item) => {

            const {onFileDownLoad} = this.props; 
            const paginatedData = AddPagination(item.parts)
            
            return (<LibraryViewerPartsTable data={ paginatedData } onFileDownLoad={onFileDownLoad}/>) } 
        }
    ];

    raiseSort = (sortColumn : Column<ReadLibraryViewer>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch } = this.props;

        return <Table data={ data } keyName="piece.id" columns={this.columns} sortColumn={sortColumn} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch}/>;
    }
}
 
export default LibraryViewerTable;