import Form, { FormState, FormData } from "../components/common/Form";
import Joi from "joi";
import authentication from "../services/authenticationService";
import { Link } from "react-router-dom";
import { IEmailUserAction } from "../models/IEmailUserAction";
import Button, { ButtonType } from "../components/common/Button";

export interface EmailUserActionDiableTwoFactorAuthenticationData extends FormData {
    authenticatorDisabled: boolean;
}

export interface EmailUserActionDiableTwoFactorAuthenticationState extends FormState {
    data: EmailUserActionDiableTwoFactorAuthenticationData;
}

class EmailUserActionDiableTwoFactorAuthentication extends Form<any, any, EmailUserActionDiableTwoFactorAuthenticationState> {
    state = {
        data: { authenticatorDisabled: false },
        errors: {},
    };

    labelChangePassword = "Disable Authenticator";

    schema = {
        authenticatorDisabled: Joi.boolean(),
    };

    doSubmit = async (buttonName : string) => {
        const { emailUserAction } = this.props;

        const action: IEmailUserAction = {
            email: emailUserAction.email,
            token: emailUserAction.token,
            password: "",
            emailActionType: emailUserAction.emailActionType,
        };

        const callResult = await authentication.completeEmailAction(action);
        if (callResult === 1) {
            let data = { ...this.state.data };
            data.authenticatorDisabled = true;
            this.setState({ data });
        }
    };

    render() {
        const { authenticatorDisabled } = this.state.data;

        if (authenticatorDisabled) {
            return (
                <>
                    <div>Your authenticator has been disabled. You can now log in without two factor authentication</div>
                    <Button buttonType={ButtonType.link} to="/">Login</Button>
                </>
            )
        }

        return (
            <>
                <div>Disable two factor authentication</div>

                <Link to="#" onClick={() => this.doSubmit("Disable Authenticator")}>
                    Disable Authenticator
                </Link>
            </>
        );
    }
}

export default EmailUserActionDiableTwoFactorAuthentication;
