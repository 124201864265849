import httpService from "./httpService";
import { ProfileDetails } from "../profile/models/ProfileDetails";

const apiEndpoint = "/Profile";

export async function getMyProfile(): Promise<ProfileDetails> {
    const { data } = await httpService.get(apiEndpoint + "/myProfile");

    const result: ProfileDetails = {
        firstName: data.firstName,
        middleNames: data.middleNames,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        usingTwoFactorAuthentication: data.usingTwoFactorAuthentication,
        twoFactorAuthenticationSettings: data.twoFactorAuthenticationSettings,
    };

    return result;
}

export async function putMyProfile(
    firstName: string,
    middleNames: string,
    lastName: string,
    email: string,
    usingTwoFactorAuthentication: boolean,
    securityCode: string,
    password: string
) {
    await httpService.put(apiEndpoint + "/myProfile", {
        firstName,
        middleNames,
        lastName,
        email,
        password,
        usingTwoFactorAuthentication,
        securityCode,
    });
}

const profileService = {
    getMyProfile,
    putMyProfile,
};

export default profileService;
