import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import LibraryViewerTable from './components/libraryViewerTable';
import withRouter from '../utils/withRouter';
import Input, { InputType } from '../components/common/Input';
import libraryViewerService, { ReadLibraryViewer } from '../services/libraryViewerService';
import authenticationService from '../services/authenticationService';
import libraryPartService, { ReadLibraryPart } from '../services/libraryPartService';

interface LibraryViewerState{
    pagedData : Paginated<ReadLibraryViewer>,
    sortColumn : Column<ReadLibraryViewer>,
    filters: Map<string, string>;
    inPad : boolean;
    myParts : boolean;
}

class LocLibraryViewer extends Component< any, any, LibraryViewerState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "piece.name", label: "Name", order: "asc" },
        filters: new Map<string, string>(),
        inPad : true,
        myParts : true
    }

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    componentDidMount = async () => {
        
        this.state.filters.set("piece.inPad", String(true));
        this.state.filters.set("parts.myPart", String(true));

        const { page, pageSize } = this.state.pagedData;        
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadLibraryViewer>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadLibraryViewer>, filters : Map<string, string> ) =>
    {
        filters.set( "piece.libraryId", String(this.LibraryId()));    

        const pagedData = await libraryViewerService.GetLibraryViewers(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    InPadToggled = async () => {
        let {inPad } = this.state;        
        inPad = !inPad;
        this.setState({inPad});
        
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;

        filters.set("piece.inPad", String(inPad));
        
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    MyPartsToggled = async () => {
        let {myParts } = this.state;        
        myParts = !myParts;
        this.setState({myParts});

        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        
        filters.set("parts.myPart", String(myParts));

        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    HandleFileDownload = ( part? : ReadLibraryPart ) =>{
        libraryPartService.DownloadFile(part!);
    }

    render(): JSX.Element {
        const { inPad, myParts, pagedData, sortColumn } = this.state;

       const accessAllPieces = authenticationService.hasAccess(["AccessAllPieces", "AccessAllPieceMetaData"]);
       const accessAllParts = authenticationService.hasAccess(["AccessAllParts", "AccessAllPartMetaData"]);

        return (
            <div>
                {(accessAllPieces || accessAllParts) &&
                <div className="d-flex">
                    <span className='me-2'>Filters: </span>
                    {accessAllPieces && <Input className="me-4" name="inPad" label="In Pad" type={InputType.checkbox} error='' value={String(inPad)} onChange={this.InPadToggled} />}
                    {accessAllParts && <Input name="myParts" label="My Parts" type={InputType.checkbox} error='' value={String(myParts)} onChange={this.MyPartsToggled} />}
                </div>
                }
                <LibraryViewerTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onFileDownLoad={this.HandleFileDownload}/>
            </div>
        );
    }
};

const LibraryViewer = withRouter(LocLibraryViewer);


export default LibraryViewer;
