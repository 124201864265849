import React from "react";
import authenticationService from "../../services/authenticationService";

interface PermissionProps{
    privilegeKey : string;
    children: React.ReactNode;
}
 
class Permission extends React.Component<PermissionProps> {
    render() { 
        const { privilegeKey, children } = this.props;
        const hasAccess = authenticationService.hasAccess( privilegeKey );

        if (hasAccess === false)
            return ( <></> );
        else
            return ( <>{children}</> );
    }
}
 
export default Permission;