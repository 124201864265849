import React from "react";
import Button, { ButtonType } from "../../components/common/Button";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { GetSecurityAccess } from "../../services/securityService";
import authenticationService from "../../services/authenticationService";

interface RollPrivilegesTableProps extends PublishedTableProps<GetSecurityAccess>{
    role : any;
    isAdministrator : boolean;
    onSave: ( additions : string[], deletions : string[] ) => void;
}

interface RollPrivilegesTableState{
    deltaAdditions : string[];
    deltaDeletions : string[];
}

class RollPrivilegesTable extends React.Component<RollPrivilegesTableProps, RollPrivilegesTableState> {

    state : RollPrivilegesTableState = {
        deltaAdditions : [],
        deltaDeletions : []
    };

    ResetState = () => {
        this.setState( {
            deltaAdditions : [],
            deltaDeletions : []
        } ); 
    }

    componentDidMount = async () => {
        this.ResetState();
    }

    componentDidUpdate(prevProps: Readonly<RollPrivilegesTableProps>, prevState: Readonly<RollPrivilegesTableState>, snapshot?: any): void {
        if (prevProps.role !== this.props.role) {
            this.ResetState();
        }
    }

    checkboxChange = (e : any) => {
        const accessKey = e.target.id;
        const newSelectedState = e.target.checked; 
        let { deltaAdditions, deltaDeletions } = this.state;

        const { data } = this.props;
        const filteredData = data.data?.filter( (x: any) => x.securityAccess === accessKey );

        if (filteredData?.length === 1)
        {   
            const item : any = filteredData[0];
            const oldSelectedState = item.selected;

            if (oldSelectedState===true) {
                if (newSelectedState===true){
                    deltaDeletions = deltaDeletions.filter( x => x !== accessKey);
                }
                else {
                    deltaDeletions.push(accessKey);
                }
            }
            else {
                if (newSelectedState===true){
                    deltaAdditions.push(accessKey);
                }
                else {
                    deltaAdditions = deltaAdditions.filter( x => x !== accessKey);
                }
            }
        }

        this.setState( { deltaAdditions, deltaDeletions })
    }

    itemIsSelected = (item : any) => {
        const { deltaAdditions, deltaDeletions } = this.state;
        const { isAdministrator } = this.props;
        if (isAdministrator)
            return true;

        let selected = item.selected;

        if (selected === true) {
            selected = !(deltaDeletions.filter( x => x === item.securityAccess).length > 0);
        }
        else {
            selected = (deltaAdditions.filter( x => x === item.securityAccess).length > 0);
        }

        return selected;
    }

    IsInDeltaList(item : any)
    {
        const { deltaAdditions, deltaDeletions } = this.state;

        return (deltaDeletions.filter( x => x === item.securityAccess).length > 0) || (deltaAdditions.filter( x => x === item.securityAccess).length > 0);
    }

    columns : Column<GetSecurityAccess>[] = [
        { key: "selected", label: "Allowed", order: "asc", content:(item : any) => { 
            const canAddRolePermission = authenticationService.hasAccess("AddRolePermission");
            const canDeleteRolePermission = authenticationService.hasAccess("DeleteRolePermission");
            
            const checked = this.itemIsSelected(item);

            let disabled = true;

            if (canAddRolePermission && !checked) {
                disabled = false;
            }

            if (canDeleteRolePermission && checked) {
                disabled = false;
            }

            if (this.IsInDeltaList(item)){
                 disabled = false;
            }

            return <><input disabled={disabled} id={item.securityAccess} type="checkbox" checked={checked} onChange={this.checkboxChange}/></>
            } 
        },
        { key: "name", label: "Name", order: "asc" },
        { key: "groupName", label: "Group", order: "asc" },
        { key: "description", label: "Description", order: "asc" }
    ];

    raiseSort = (sortColumn : Column<GetSecurityAccess>) => {
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    handleAuditParams = (item: any) => {
        return {
            entityName : "e_suite.Database.Core.Tables.Domain.UserRole",
            primaryKey : "{\"Id\":"+item.id+"}"
            }
    }

    HandleClickCancel = () => {
        this.setState( { deltaAdditions : [], deltaDeletions : [] })
    }

    HandleClickSave = () => {
        const { onSave } = this.props;
        const { deltaAdditions, deltaDeletions } = this.state;

        onSave(deltaAdditions, deltaDeletions);

        this.setState( { deltaAdditions : [], deltaDeletions : [] })
    }
    
    render() { 
        const { deltaAdditions, deltaDeletions } = this.state;
        const { data, isAdministrator } = this.props;

        const canAddRolePermission = authenticationService.hasAccess("AddRolePermission");
        const canDeleteRolePermission = authenticationService.hasAccess("DeleteRolePermission");

        const hasChanges = !(deltaAdditions.length + deltaDeletions.length === 0);

        return <>
            <Table data={ data } keyName="securityAccess" columns={this.columns} />
            {(!isAdministrator && (canAddRolePermission || canDeleteRolePermission)) && <>
                <Button disabled={!hasChanges} buttonType={ButtonType.secondary} onClick={this.HandleClickCancel}>
                    Cancel
                </Button>
                <Button disabled={!hasChanges} buttonType={ButtonType.primary} onClick={this.HandleClickSave}>
                    Save
                </Button>
            </>}
        </>;
    }
}
 
export default RollPrivilegesTable;