import { Navigate } from "react-router-dom";
import Joi from "joi";
import Form, { FormData, FormState } from "../components/common/Form";
import authentication from "../services/authenticationService";
import { InputType } from "../components/common/Input";
import Button, { ButtonType } from "../components/common/Button";

export interface LoginFormStateData extends FormData {
    username: string;
    password: string;
    tfaNeeded: boolean;
    requestTfaRemoval: boolean;
    securityCode: string;
}

export interface LoginFormState extends FormState {
    data: LoginFormStateData;
}

class LoginForm extends Form<any, any, LoginFormState> {
    state : LoginFormState = {
        data: {
            username: "",
            password: "",
            tfaNeeded: false,
            requestTfaRemoval: false,
            securityCode: "",
        },
        errors: {},
        delayValidation: true
    };

    schema = {
        username: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label("e-mail"),
        password: Joi.string().required().label("Password"),
        tfaNeeded: Joi.boolean().required(),
        requestTfaRemoval: Joi.boolean().required(),
        securityCode: Joi.string().allow("").label("Authenticate"),
    };

    doSubmit = async (buttonName : string) => {
        const { data } = this.state;
        await this.performLogin(data);
    };

    authenticationWorkAround = async () => {
        const data: LoginFormStateData = { ...this.state.data };
        data.requestTfaRemoval = true;

        await this.performLogin(data);

        this.setState({ data });
    };

    private async performLogin(data: LoginFormStateData) {
        try {
            let result = await authentication.login(data.username, data.password, data.securityCode, data.requestTfaRemoval);

            switch (result) {
                case 1: //requires tfa
                    const { data } = this.state;

                    if (data.tfaNeeded === true) {
                        //TFA removal Request accepted.
                    } else {
                        data.tfaNeeded = true;

                        this.setState({ data });
                    }
                    break;
                case 2: //logged in
                    window.location.href = "/";
                    break;
                default:
                    break; //treat at though not logged in.
            }
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    }

    render() {
        const { tfaNeeded, requestTfaRemoval } = this.state.data;

        if (authentication.getCurrentUser()) return <Navigate to="/" />;

        const requestTfaRemovalPanel = <div>An email has been sent to you so that you can regain control of your account.</div>;

        const loginPanel = (
            <form onSubmit={this.handleSubmit}>
                {this.renderError("_general")}
                {this.renderInput("username", "e-mail")}
                {this.renderInput("password", "Password", InputType.password)}
                {this.renderButton("Login")}
                <Button buttonType={ButtonType.link} to="/forgot-password">I forgot my password</Button>
            </form>
        );

        const tfaPanel = (
            <form onSubmit={this.handleSubmit}>
                {this.renderError("_general")}
                {this.renderInput("securityCode", "Authenticate")}
                {this.renderButton("Authenticate")}
                <Button buttonType={ButtonType.link} onClick={this.authenticationWorkAround}>My Authenticator is not working</Button>
            </form>
        );

        return (
            <div className="loginForm">
                <h1>Login</h1>
                {requestTfaRemoval ? requestTfaRemovalPanel : tfaNeeded ? tfaPanel : loginPanel}
            </div>
        );
    }
}

export default LoginForm;
