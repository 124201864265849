import React from "react";
import withRouter from "../../utils/withRouter";
import { Paginated } from "../../services/Paginated";
import securityService, { GetRoleSecurityAccess, GetSecurityAccess, ReadRole } from "../../services/securityService";
import Column from "../../components/common/columns";
import { MakeGeneralIdRef } from "../../utils/GeneralIdRef";
import RollPrivilegesTable from "./RollPrivilegesTable";
import authentication from "../../services/authenticationService";
import authenticationService from "../../services/authenticationService";

interface RolePrivilegesProps {
    role : ReadRole
}
 
interface RolePrivilegesState {
    accessList : Paginated<GetSecurityAccess>,
    accessRightsForRole : Paginated<GetRoleSecurityAccess>,
    pagedData : Paginated<GetRoleSecurityAccess>,
    sortColumn : Column<GetRoleSecurityAccess>,
    filters: Map<string, string>;

}
 
class LocRolePrivileges extends React.Component<RolePrivilegesProps, RolePrivilegesState> {
    state : RolePrivilegesState = { 
        accessList : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        accessRightsForRole : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>()
     }

     componentDidMount = async () => {
        await this.updatePage();        
    }

    async componentDidUpdate(prevProps: Readonly<RolePrivilegesProps>, prevState: Readonly<RolePrivilegesState>, snapshot?: any) {
        if (prevProps.role !== this.props.role) {
            await this.updatePage();
        }
    }

    updatePage = async() =>
    {
        const { page, pageSize } = this.state.pagedData;

        await this.changePage(page, pageSize);
    }

    changePage = async(page: number, pageSize : number) => {
        const { role } = this.props;

        const accessList = await securityService.GetAccessList(0, 10, "name", true );
     
        const roleAccessfilters = new Map<string, string>();
        roleAccessfilters.set( "roleId", String(role.id));
        const accessRightsForRole = await securityService.GetRoleAccess(0,10,"name",true, roleAccessfilters);

        const pagedData = this.complieAccessRightsPagedData( accessList.data, accessRightsForRole.data );

        this.setState({ accessList, accessRightsForRole, pagedData });
    }

    IsItemSelected = (securityAccess: string, roleAccess: unknown[] | undefined) : boolean => {
        if (roleAccess === undefined)
            return false;

        const filtered = roleAccess?.filter( (x) => (x as any).securityAccess === securityAccess);
        
        return filtered!.length > 0;
    }

    complieAccessRightsPagedData = (masterList: GetSecurityAccess[] | undefined, roleAccess: unknown[] | undefined) : Paginated<GetRoleSecurityAccess> => {
        if ( masterList === undefined ) {
            return { page: 1,
                pageSize : 10,
                count: 0,
                totalPages: 1,
                data: [] }
        }
        
        if (roleAccess === undefined) {
            return { page: 1,
                pageSize : 10,
                count: 0,
                totalPages: 1,
                data: (masterList as GetRoleSecurityAccess[]) }
        }

        const accessrightsForRole : Paginated<GetRoleSecurityAccess> = { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: []
        }

        accessrightsForRole.data = masterList.map( value => {
            let item : any = value
            item.selected = this.IsItemSelected(item.securityAccess, roleAccess);
            return item
        })

        return accessrightsForRole;
    }

    handleSave = async ( additions : string[], deletions : string[] ) => {
        const { role } = this.props;

        const roleGeneralIdRef = MakeGeneralIdRef(role.id, role.guid);

        if (additions.length > 0)
        {
            if (authenticationService.hasAccess("AddRolePermission"))
                await securityService.AddRoleAccess( roleGeneralIdRef, additions);
        }

        if (deletions.length > 0)
        {
            if (authenticationService.hasAccess("DeleteRolePermission"))
                await securityService.DeleteRoleAccess( roleGeneralIdRef, deletions);
        }

        await this.updatePage();
    }

    render() {  
        const { role } = this.props;
        const { pagedData } = this.state;

        const isAdministrator = role.isAdministrator || role.isSuperUser;
        
        return (
            <>
                <RollPrivilegesTable role={role.id} isAdministrator={isAdministrator} data={pagedData} onSave={this.handleSave}/>
            </>
        );
    }
}
 
const RolePrivileges = withRouter(LocRolePrivileges);

export default RolePrivileges;