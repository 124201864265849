import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadRole } from "../../services/securityService";
import authenticationService from "../../services/authenticationService";

class RolesTable extends React.Component<PublishedTableProps<ReadRole>> {
    columns : Column<ReadRole>[] = [
        { key: "name", label: "Role Name", order: "asc", link: "/role/{0}" }
    ];

    raiseSort = (sortColumn : Column<ReadRole>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    canDelete = (item : ReadRole) => {
        return !(item.isAdministrator || item.isSuperUser);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const editPath = authenticationService.hasAccess("EditRole") ? "{0}" : undefined
        const doDelete = authenticationService.hasAccess("DeleteRole") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} editPath={editPath} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} canEdit={this.canDelete} canDelete={this.canDelete} onDelete={doDelete}/>;
    }
}
 
export default RolesTable;