import { Component } from 'react';
import Column from '../components/common/columns';
import Button, { ButtonType } from '../components/common/Button';
import withRouter from '../utils/withRouter';
import securityService, { ReadRole } from '../services/securityService';
import RolesTable from './components/rolesTable';
import Permission from '../components/common/Permission';
import { toast } from 'react-toastify';

interface SecurityState{
    // pagedData : Paginated<ReadPartInstrument>,
    // sortColumn : Column<ReadPartInstrument>,
    //filters: Map<string, string>;
}

class LocSecurity extends Component< any, any, SecurityState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "instrumentName", label: "Instrument Name", order: "asc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadRole>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadRole>, filters : Map<string, string> ) =>
    {
        const pagedData = await securityService.GetRoles(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadRole) => {
        await securityService.DeleteRole(keyValue?.id, keyValue?.guid);
        toast.success( "Role " + keyValue?.name + " deleted");
        this.componentDidMount();
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="CreateRole">
                    <div>
                        <Button className="me-1" buttonType={ButtonType.primary} to="add">Add</Button>
                    </div>
                    <hr/>
                </Permission>
                <RolesTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const Security = withRouter(LocSecurity);


export default Security;
