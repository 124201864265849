import * as React from "react";
import authentication from "../services/authenticationService";

class Logout extends React.Component {
    componentDidMount() {
        authentication.logout();
        window.location.href = "/";
    }

    render() {
        return <div>Logging out</div>;
    }
}

export default Logout;
