import { MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "/Library";

interface LibraryBase{
    guid? : string;
    name : string;
    folderName: string;
    published : boolean;
}

export interface CreateLibrary extends LibraryBase{

}

export interface ReadLibrary extends LibraryBase{
    id? : bigint;
}

export interface EditLibrary extends LibraryBase{
    id? : bigint;
}

export async function GetLibraries( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadLibrary>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadLibrary>>(apiEndpoint + "/libraries", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetLibrary( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadLibrary>(apiEndpoint + "/library?" + params );

    return data;
}

export async function PostLibrary( library : CreateLibrary): Promise<void>{
    await httpService.post(apiEndpoint + "/library", library);
}

export async function PutLibrary( library : EditLibrary ): Promise<void>{
    await httpService.put(apiEndpoint + "/library", library);
}

export async function DeleteLibrary( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/library", { data: generalIdRef });
}

const libraryService = {
    GetLibraries,
    GetLibrary,
    PostLibrary,
    PutLibrary,
    DeleteLibrary
};

export default libraryService;
