import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import libraryPieceService, { ReadLibraryPiece } from '../services/libraryPieceService';
import Button, { ButtonType } from '../components/common/Button';
import LibraryPiecesTable from './components/libraryPiecesTable';
import withRouter from '../utils/withRouter';
import Permission from '../components/common/Permission';
import { selectFile } from '../utils/fileSelector';
import { toast } from 'react-toastify';
import Progressbar from '../components/common/Progressbar';

interface LibraryPiecesState{
    pagedData : Paginated<ReadLibraryPiece>,
    sortColumn : Column<ReadLibraryPiece>,
    filters: Map<string, string>,
    uploadFile: File | undefined,
    uploadPercent: number | undefined;
}

class LocLibraryPieces extends Component< any, any, LibraryPiecesState> {
    state : LibraryPiecesState= {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>(),
        uploadFile : undefined,
        uploadPercent: undefined
    }

    LibraryId = () : bigint => {
        const { libraryId } = this.props.router.params;
        return libraryId;
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadLibraryPiece>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadLibraryPiece>, filters : Map<string, string> ) =>
    {
        filters.set( "libraryId", String(this.LibraryId()));
        const pagedData = await libraryPieceService.GetLibraryPieces(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadLibraryPiece) => {
        await libraryPieceService.DeleteLibraryPiece( keyValue?.id, keyValue?.guid);
        toast.success( "Piece " + keyValue?.name + " deleted");
        this.componentDidMount();
    }

    HandleDiscoverClick = async () => {
        await libraryPieceService.AutoPopulate(this.LibraryId());
    }
 
    HandleUploadClick = async () => {
        let files = await selectFile(".zip", false);

        let uploadFile = files as File;

        this.setState( { uploadFile });

        await libraryPieceService.UploadFile(uploadFile, this.LibraryId(), undefined, this.HandleUploadProgress );
        toast.info("New Piece added");
    }

    HandleUploadProgress = async (percent : number ) => {
        let uploadPercent = percent;
        this.setState( { uploadPercent });
    }

    render(): JSX.Element {
        const { pagedData, sortColumn, uploadFile, uploadPercent } = this.state;

        return (
            <div>
                <Permission privilegeKey="AddPiece">
                    <div>
                        <Button className="me-1" buttonType={ButtonType.primary} to="add">Add</Button>
                        <Button className="me-1" buttonType={ButtonType.primary} onClick={this.HandleDiscoverClick} >Discover</Button>
                        <Button className="me-1" buttonType={ButtonType.primary} onClick={this.HandleUploadClick}>Upload</Button>
                        {uploadFile !== null && <Progressbar text={uploadFile?.name??""} percent={uploadPercent??0}/>}
                    </div>
                    <hr/>
                </Permission>
                <LibraryPiecesTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const LibraryPieces = withRouter(LocLibraryPieces);


export default LibraryPieces;
