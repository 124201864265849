interface ErrorProps {
    error?: string
}
 
function ErrorBlock(props : ErrorProps)
{
    const { error } = props;

    return ( <>{error && <div className="alert alert-danger">{error}</div>}</> );
}
 
export default ErrorBlock;
