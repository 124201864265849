import { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import Button, { ButtonType } from '../components/common/Button';
import { toast } from 'react-toastify';
import InstrumentSynonymService, { ReadInstrumentSynonym } from '../services/instrumentSynonymService';
import InstrumentSynonymTable from './components/instrumentSynonymTable';
import withRouter from '../utils/withRouter';
import Permission from '../components/common/Permission';

interface InstrumentSynonymsState{
    pagedData : Paginated<ReadInstrumentSynonym>,
    sortColumn : Column<ReadInstrumentSynonym>,
    filters: Map<string, string>;
}

class LocInstrumentSynonyms extends Component< any, any, InstrumentSynonymsState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "name", label: "Name", order: "asc" },
        filters: new Map<string, string>()
    }

    InstrumentId = () => {
        const { instrumentId } = this.props.router.params;
        return instrumentId;
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;       
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadInstrumentSynonym>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadInstrumentSynonym>, filters : Map<string, string> ) =>
    {
        filters.set( "instrumentId", String(this.InstrumentId()));
        const pagedData = await InstrumentSynonymService.GetInstrumentSynonyms(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    onDelete = async ( keyValue? : ReadInstrumentSynonym) => {
        await InstrumentSynonymService.DeleteInstrumentSynonym( keyValue?.id, keyValue?.guid);

        toast.success( "Synonym " + keyValue?.name + " deleted");
        this.componentDidMount();
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="CreateInstrumentSynonym">
                    <div>
                        <Button buttonType={ButtonType.primary} to="add">Add</Button>
                    </div>
                    <hr/>
                </Permission>
                <InstrumentSynonymTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete}/>
            </div>
        );
    }
};

const InstrumentSynonyms = withRouter(LocInstrumentSynonyms);

export default InstrumentSynonyms;
