import React from "react";
import Select, { Option } from "./../common/Select";
import { GeneralIdRef, MakeGeneralIdRef } from "./../../utils/GeneralIdRef";
import instrumentService from "../../services/instrumentService";

interface InstrumentPickerProps {
    name: string;
    label: string;
    error?: string;
    value: any;
    onChange?: (name: string, value: GeneralIdRef) => void;
}

interface InstrumentPickerState {
    options?: Option[];
}

class InstrumentPicker extends React.Component<InstrumentPickerProps, InstrumentPickerState> {
    state = { options: undefined };

    async componentDidMount() {
        const pagedData = await instrumentService.GetInstruments(0, 10, "name", true);

        const options: Option[] = (pagedData.data).map((x) => {
            return {
                _id: String(x.id),
                name: x.name,
            };
        });

        this.setState({ options });
    }

    handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { onChange } = this.props;
        const input = e.currentTarget;

        const generalIdRef = MakeGeneralIdRef( BigInt(input.value));

        if (onChange) onChange(input.name, generalIdRef);
    };

    render() {
        const { name, label, error, value } = this.props;
        const { options } = this.state;

        return (
            <Select name={name} label={label} error={error} value={String(value?.id)} options={options} includeBlankFirstEntry={false} onChange={this.handleChange} />
        );
    }
}

export default InstrumentPicker;
