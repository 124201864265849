import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadPerformanceReportSummary } from "../../services/diagnosticService";
import Button, { ButtonType } from "../../components/common/Button";
import { Link } from "react-router-dom";

class PerformanceSummaryTable extends React.Component<PublishedTableProps<ReadPerformanceReportSummary>> {
    columns : Column<ReadPerformanceReportSummary>[] = [
        { key: "host", label: "Host", order: "asc", content: (item) => {
            return <Link to={`/performance/${item.host}/${item.controllerName}/${item.actionName}/${item.requestType}` }>{item.host}</Link>
        } },
        { key: "controllerName", label: "Controller", order: "asc" },
        { key: "actionName", label: "Action", order: "asc" },
        { key: "requestType", label: "Type", order: "asc" },
        { key: "count", label: "Count", order: "asc" },
        { key: "minTotalTimeMs", label: "Min", order: "asc" },
        { key: "maxTotalTimeMS", label: "Max", order: "asc" },
        { key: "avgTotalTimeMs", label: "Avg", order: "asc" },
        { key: "sumTotalTimeMs", label: "Sum", order: "desc" },
    ];

    raiseSort = (sortColumn : Column<ReadPerformanceReportSummary>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch } = this.props;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} onSort={this.raiseSort} onSearch={onSearch} onChangePage={onChangePage}/>;
    }
}
 
export default PerformanceSummaryTable;