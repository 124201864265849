import { Component } from 'react';
import Column from '../components/common/columns';
import withRouter from '../utils/withRouter';
import diagnosticService, { ReadPerformanceReportSummary } from '../services/diagnosticService';
import { Paginated } from '../services/Paginated';
import PerformanceSummaryTable from './components/performanceSummaryTable';

interface PerformanceState{
    pagedData : Paginated<ReadPerformanceReportSummary>,
    sortColumn : Column<ReadPerformanceReportSummary>,
    filters: Map<string, string>;
}

class LocPerformance extends Component< any, any, PerformanceState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "sumTotalTimeMs", label: "Sum", order: "desc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadPerformanceReportSummary>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadPerformanceReportSummary>, filters : Map<string, string> ) =>
    {
        const pagedData = await diagnosticService.GetPerformanceReportSummary(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <PerformanceSummaryTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch}/>
            </div>
        );
    }
};

const Performance = withRouter(LocPerformance);

export default Performance;
