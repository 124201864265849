import Joi from "joi";
import { toast } from "react-toastify";
import Form, { FormState, FormData, FormError } from "../components/common/Form";
import profileService from "../services/profileService";
import { InputType } from "../components/common/Input";
import { TwoFactorAuthenticationSettings } from "./models/TwoFactorAuthenticationSettings";

export interface ProfileStateData extends FormData {
    firstName: string;
    middleNames: string;
    lastName: string;
    email: string;
    newPassword: string;
    confirmPassword: string;
    originalUsingTwoFactorAuthentication: boolean;
    usingTwoFactorAuthentication: boolean;
    tfaCode: string;
}

export interface ProfileState extends FormState {
    data: ProfileStateData;
    twoFactorAuthenticationSettings: TwoFactorAuthenticationSettings;
}

class Profile extends Form<any, any, ProfileState> {
    state = {
        data: {
            firstName: "",
            middleNames: "",
            lastName: "",
            email: "",
            newPassword: "",
            confirmPassword: "",
            originalUsingTwoFactorAuthentication: false,
            usingTwoFactorAuthentication: false,
            tfaCode: ""
        },
        errors: {},
        twoFactorAuthenticationSettings: {
            manualEntrySetupCode: "",
            qrCodeImageUrl: "",
        },
    };

    labelFirstName = "First Name";
    labelMiddleNames = "Middle Name(s)";
    labelLastName = "Last Name";
    labelEmail = "E-Mail";
    labelNewPassword = "New Password";
    labelConfirmPassword = "Confirm Password";

    labelUsingTwoFactorAuthentication = "2 Factor Authentication";
    labelTfaCode = "Authentication code";
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        firstName: Joi.string().required().label(this.labelFirstName),
        middleNames: Joi.string().allow("").required().label(this.labelMiddleNames),
        lastName: Joi.string().required().label(this.labelLastName),
        email: Joi.string()
            .required()
            .email({ tlds: { allow: false } })
            .label(this.labelEmail),
        newPassword: Joi.string().allow("").min(5).label(this.labelNewPassword),
        confirmPassword: Joi.string()
            .when("newPassword", {
                is: "",
                then: Joi.allow("").optional(),
                otherwise: Joi.valid(Joi.ref("newPassword")).error(() => {
                    const e = new Error("Passwords must match");
                    e.name = "confirmPassword";
                    return e;
                }),
            })
            .label(this.labelConfirmPassword),

        originalUsingTwoFactorAuthentication: Joi.boolean().required(),
        usingTwoFactorAuthentication: Joi.boolean().required().label(this.labelUsingTwoFactorAuthentication),

        tfaCode: Joi.string()
            .when("originalUsingTwoFactorAuthentication", {
                    is: Joi.ref("usingTwoFactorAuthentication"),
                    then: Joi.allow("").optional(),
                    otherwise: Joi.when("usingTwoFactorAuthentication", {
                        is: true,
                        then: Joi.string()
                            .length(6)
                            .required()
                            .error(() => {
                                const e = new Error("You must enter the code from the authenticator");
                                e.name = "tfaCode";
                                return e;
                            }),
                        otherwise: Joi.allow("").optional(),
                    }),
                })
            .label(this.labelTfaCode),
    };

    doMount = async () => {
        try {
            const profile = await profileService.getMyProfile();
        
            if (profile) {
                const { firstName, middleNames, lastName, email, usingTwoFactorAuthentication, twoFactorAuthenticationSettings } = profile;

                const data = {
                    firstName,
                    middleNames,
                    lastName,
                    email,
                    newPassword: "",
                    confirmPassword: "",
                    originalUsingTwoFactorAuthentication: usingTwoFactorAuthentication,
                    usingTwoFactorAuthentication,
                    tfaCode: ""
                };
                this.setState({ data, twoFactorAuthenticationSettings });
            }
        }
        catch(ex:any) {
            this.handleFatalError(ex)
        }
    }

    doSubmit = async (buttonName : string) => {
        try {
            const { firstName, middleNames, lastName, email, usingTwoFactorAuthentication, tfaCode, newPassword, confirmPassword } = this.state.data;

            let password = "";

            if (newPassword === confirmPassword) password = newPassword;

            try{
                await profileService.putMyProfile(firstName, middleNames, lastName, email, usingTwoFactorAuthentication, tfaCode, password);

                await this.componentDidMount();
                toast.info("Your profile settings have been saved");
            }
            catch(ex:any) {
                this.handleGeneralError(ex);
                toast.error("Failed to save changes:" + ex.response.data.detail);
            }
        } catch (ex: any) {
            if (ex.response && ex.response.status === 400) {
                const errors: FormError = { ...this.state.errors };
                errors["email"] = ex.response.data;
                this.setState({ errors });
            }
        }
    };

    render() {
        const { twoFactorAuthenticationSettings } = this.state;
        const { originalUsingTwoFactorAuthentication, newPassword } = this.state.data;

        const tfaEnabled = originalUsingTwoFactorAuthentication ? "Enabled" : "Disabled";

        let tfaImageBlock = null;
        if (twoFactorAuthenticationSettings)
            tfaImageBlock = (
                <div className="tfaInfo">
                    <label>{twoFactorAuthenticationSettings.manualEntrySetupCode}</label>
                    <img src={twoFactorAuthenticationSettings.qrCodeImageUrl} alt={twoFactorAuthenticationSettings.manualEntrySetupCode} />
                </div>
            );

        let tfaSection : JSX.Element;
            tfaSection = (
                <div>
                    {this.renderToggle("usingTwoFactorAuthentication", this.labelUsingTwoFactorAuthentication)}
                    {tfaImageBlock}
                    {this.renderInput("tfaCode", this.labelTfaCode)}
                </div>
            );

        let passwordSection = <>{this.renderInput("newPassword", this.labelNewPassword, InputType.password)}</>;

        if (newPassword !== "")
            passwordSection = (
                <>
                    {this.renderInput("newPassword", this.labelNewPassword, InputType.password)}
                    {this.renderInput("confirmPassword", this.labelConfirmPassword, InputType.password)}
                </>
            );
               
        return (
            <div>
                <h1>Profile</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("email", this.labelEmail, InputType.text, true)}
                    {this.renderInput("firstName", this.labelFirstName)}
                    {this.renderInput("middleNames", this.labelMiddleNames)}
                    {this.renderInput("lastName", this.labelLastName)}

                    {passwordSection}

                    {this.renderDropSection("turnOnTfa", <label>Two Factor Authentication {tfaEnabled}</label>, tfaSection)}
                    
                    {this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}                    
                </form>
            </div>
        );
    }
}

export default Profile;
