import React from "react";
import ErrorBlock from "./ErrorBlock";

export enum InputType {
    button = "button",
    checkbox = "checkbox",
    color = "color",
    date = "date",
    datetimelocal = "datetime-local",
    email = "email",
    file = "file",
    hidden = "hidden",
    image = "image",
    month = "month",
    number = "number",
    password = "password",
    radio = "radio",
    range = "range",
    reset = "reset",
    search = "search",
    submit = "submit",
    tel = "tel",
    text = "text",
    time = "time",
    url = "url",
    week = "week",
}

export interface InputProps {
    className?: string;
    name: string;
    label: string;
    error: string;
    readOnly?: boolean;
    type: InputType;
    value?: string | number | readonly string[] | undefined;
    defaultValue?: string | number | readonly string[] | undefined;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function Input(props: InputProps) {
    const { name, label, error, readOnly, type, value, defaultValue, onChange, ...rest } = props;

    let showValue = value;
    let checked : boolean = false;
    
    let divClassName = "form-group"
    let labelClassName = "";
    let className = "form-control";

    if (type === InputType.checkbox)
    {
        checked = (value === String(true));
        showValue = undefined;
        divClassName = "form-check";
        className = "form-check-input";
        labelClassName = "form-check-label";
    }

    if (props.className !== undefined) {
        divClassName = props.className + " " + divClassName;
    }

    return (
        <div className={divClassName}>
            <label className={labelClassName} htmlFor={name}>
                {label}
            </label>
            <input
                {...rest}
                id={name}
                type={type}
                className={className}
                name={name}
                onChange={onChange}
                disabled={readOnly}
                value={showValue}
                checked={checked}
                defaultValue={defaultValue}
            />
            <ErrorBlock error={error}/>
        </div>
    );
}

export default Input;
