export interface GeneralIdRef {
    id? : bigint
    guid? : string;
}

export function MakeGeneralIdRef( id? : bigint, guid?: string) : GeneralIdRef
{
    let result : GeneralIdRef = {};
    result.id = id;
    result.guid = guid;
    return result;
}

export function MakeGeneralIdRefParams( id? : bigint, guid? : string)
{
    let params = "";
    if (id !== undefined)
        params += "id="+ id;
    
    if (guid !== undefined){
        if (params !== ""){
            params += "&"
        }
        params += "guid=" + guid;
    }

    return params
}

export function AppendParam( params : string, key: string, value?:string)
{   
    if (value !== undefined)
    {
        if (params !== ""){
            params += "&"
        }
        params += key + "=" + value;

        return params;
    }

    return params;
}