import react, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

export enum ButtonType{
    none,
    primary,
    secondary,
    success,
    danger,
    warning,
    info,
    light,
    dark,
    link,
    theme
}

export interface ButtonProps<T>{
    className?: string;
    id?:string;
    name?:string;
    keyValue?: T;
    children: React.ReactNode;
    buttonType : ButtonType;
    disabled?: boolean;
    to?: string;
    forceHtml? : boolean;
    onClick?: ( keyValue : T | undefined ) => void;
}

class Button<T> extends react.Component<ButtonProps<T>> {
    Click = (e : SyntheticEvent) => {       
        const {keyValue, onClick} = this.props;

        if (onClick)
        {
            e.preventDefault();
            onClick(keyValue);
        }
    }
    
    render() {
        const { id, className, children, buttonType, disabled, name, to, forceHtml } = this.props;

        let classNames = "";

        switch (buttonType)
        {
            case ButtonType.primary:
                classNames = "btn btn-primary";
                break;
            case ButtonType.secondary:
                classNames = "btn btn-secondary";
                break;
            case ButtonType.success:
                classNames = "btn btn-success";
                break;
            case ButtonType.danger:
                classNames = "btn btn-danger";
                break;
            case ButtonType.warning:
                classNames = "btn btn-warning";
                break;
            case ButtonType.info:
                classNames = "btn btn-info";
                break;
            case ButtonType.light:
                classNames = "btn btn-light";
                break;
            case ButtonType.dark:
                classNames = "btn btn-dark";
                break;
            case ButtonType.link:
                classNames = "btn btn-link";
                break;
            case ButtonType.theme:
                classNames = "btn"
                break;
            case ButtonType.none:
                classNames = ""
                break;
        }

        if (className !== undefined)
        {
            classNames += " " + className;
        }
        
        if (to !== undefined){
            if (forceHtml === true){
                return <a id={id} className={classNames} href={to} >{children}</a>    
            }
            return <Link id={id} className={classNames} to={to} >{children}</Link>
        }

        return <button id={id} className={classNames} name={name} disabled={disabled} onClick={this.Click}>{children}</button>;
    }
}

export default Button;