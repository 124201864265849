import { GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "/Instrument";

export interface InstrumentSynonymBase{
    guid? : string,
    name : string,
    instrumentId : GeneralIdRef
}

export interface ReadInstrumentSynonym extends InstrumentSynonymBase{
    id : bigint
}

export interface CreateInstrumentSynonym extends InstrumentSynonymBase{

}

export interface EditInstrumentSynonym extends InstrumentSynonymBase{
    id : bigint
}


export async function GetInstrumentSynonyms( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadInstrumentSynonym>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadInstrumentSynonym>>(apiEndpoint + "/instrumentSynonyms", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetInstrumentSynonym( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadInstrumentSynonym>(apiEndpoint + "/instrumentSynonym?" + params );

    return data;
}

export async function PostInstrumentSynonym( create : CreateInstrumentSynonym): Promise<void>{
    await httpService.post(apiEndpoint + "/instrumentSynonym", create);
}

export async function PutInstrumentSynonym( edit : EditInstrumentSynonym ): Promise<void>{
    await httpService.put(apiEndpoint + "/instrumentSynonym", edit);
}

export async function DeleteInstrumentSynonym( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/instrumentSynonym", { data: generalIdRef });
}

const InstrumentSynonymService = {
    GetInstrumentSynonyms,
    GetInstrumentSynonym,
    PostInstrumentSynonym,
    PutInstrumentSynonym,
    DeleteInstrumentSynonym
};

export default InstrumentSynonymService;
