import React from "react";
import Column from "../../components/common/columns";
import Table, { PublishedTableProps } from "../../components/common/Table";
import { ReadInstrument } from "../../services/instrumentService";
import Button, { ButtonType } from "../../components/common/Button";

export interface UserInstruments extends ReadInstrument {
    selected : boolean
}

export interface UserInstrumentsTableProps extends PublishedTableProps<UserInstruments>{
    isAdministrator? : boolean;
    onSave?: ( additions : UserInstruments[], deletions : UserInstruments[] ) => void;
}

interface UserInstrumentsTableState{
    deltaAdditions : UserInstruments[];
    deltaDeletions : UserInstruments[];
}

class UserInstrumentsTable extends React.Component<UserInstrumentsTableProps, UserInstrumentsTableState> {
    state : UserInstrumentsTableState = {
        deltaAdditions : [],
        deltaDeletions : []
    };

    columns : Column<UserInstruments>[] = [
        { key: "name", label: "Name", order: "asc", content:(item : any) => { 
                return <><input id={"tableItem" + item.id} type="checkbox" checked={this.itemIsSelected(item)} onChange={() => this.checkboxChange((item))} /> <label htmlFor={"tableItem" + item.id}>{item.name}</label></> } },
    ];
    
    checkboxChange = (item : UserInstruments) => {
        let { deltaAdditions, deltaDeletions } = this.state;

        if (item.selected===true) {
            const itemIndex = deltaDeletions.indexOf( item );

            if (itemIndex > -1) {
                deltaDeletions = deltaDeletions.filter( x => x !== item );
            }
            else {
                deltaDeletions.push(item);
            }
         }
         else {
            const itemIndex = deltaAdditions.indexOf( item );

             if (itemIndex === -1){
                 deltaAdditions.push(item);
             }
             else {
                 deltaAdditions = deltaAdditions.filter( x => x !== item);
             }
        }

        this.setState( { deltaAdditions, deltaDeletions })
    }

    itemIsSelected = (item : UserInstruments) => {
        const { deltaAdditions, deltaDeletions } = this.state;
        const { isAdministrator } = this.props;
        if (isAdministrator)
            return true;

        let selected = item.selected;

        if (selected === true) {
             selected = !(deltaDeletions.filter( x => x === item).length > 0);
         }
         else {
             selected = (deltaAdditions.filter( x => x === item).length > 0);
        }

        return selected;
    }

    HandleClickCancel = () => {
        this.setState( { deltaAdditions : [], deltaDeletions : [] })
    }

    HandleClickSave = () => {
        const { onSave } = this.props;
        const { deltaAdditions, deltaDeletions } = this.state;
        
        if (onSave !== undefined)
            onSave(deltaAdditions, deltaDeletions);

        this.setState( { deltaAdditions : [], deltaDeletions : [] })
    }

    render() { 
        const { deltaAdditions, deltaDeletions } = this.state;
        const { data, isAdministrator } = this.props;

        const hasChanges = !(deltaAdditions.length + deltaDeletions.length === 0);

        return <>
            <Table data={ data } keyName="id" columns={this.columns}/>
            {!isAdministrator && <>
                <Button className="btn-spaced" disabled={!hasChanges} buttonType={ButtonType.secondary} onClick={this.HandleClickCancel}>
                    Cancel
                </Button>
                <Button className="btn-spaced" disabled={!hasChanges} buttonType={ButtonType.primary} onClick={this.HandleClickSave}>
                    Save
                </Button>
            </>}
        </>
    }
}
 
export default UserInstrumentsTable;