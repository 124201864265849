import { AxiosProgressEvent } from "axios";
import { GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "/LibraryPiece";

interface LibraryPieceBase{
    guid? : string;
    name : string;
    folderName: string;
    composer: string,
    arranger: string,
    reference: string;
    inPad: boolean;
    category: string,
    notes:string,
    duration: string,
    libraryId: GeneralIdRef;
}

export interface CreateLibraryPiece extends LibraryPieceBase{

}

export interface ReadLibraryPiece extends LibraryPieceBase{
    id? : bigint;
}

export interface EditLibraryPiece extends LibraryPieceBase{
    id? : bigint;
}

export async function GetLibraryPieces( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadLibraryPiece>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadLibraryPiece>>(apiEndpoint + "/libraryPieces", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );

    return response.data;
}

export async function GetLibraryPiece( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadLibraryPiece>(apiEndpoint + "/libraryPiece?" + params );

    return data;
}

export async function PostLibraryPiece( library : CreateLibraryPiece): Promise<void>{
    await httpService.post(apiEndpoint + "/libraryPiece", library);
}

export async function PutLibraryPiece( library : EditLibraryPiece ): Promise<void>{
    await httpService.put(apiEndpoint + "/libraryPiece", library);
}

export async function DeleteLibraryPiece( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/libraryPiece", { data: generalIdRef });
}

export async function AutoPopulate( id? : bigint, guid?: string): Promise<void>{
    const params = MakeGeneralIdRefParams(id, guid);

    await httpService.post(apiEndpoint + "/autoPopulate?" + params );
}

export async function UploadFile( file : File, id?: bigint, guid?: string, onProgress?: (percent:number) => void )
{
    const onUploadProgress = (event:AxiosProgressEvent) => {       
        if (onProgress !== null )
            onProgress!(event.progress! * 100);
      };

    let formData = new FormData()
    formData.append("file", file);
    if (id !== undefined)
        formData.append("Id", String(id));
    if (guid !== undefined)
        formData.append("Guid", String(guid));

    await httpService.post(apiEndpoint + "/file", formData,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        } 
    );
}

const libraryPieceService = {
    GetLibraryPieces,
    GetLibraryPiece,
    PostLibraryPiece,
    PutLibraryPiece,
    DeleteLibraryPiece,
    AutoPopulate,
    UploadFile
};

export default libraryPieceService;
