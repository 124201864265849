import React from 'react';
import { Navigate } from 'react-router-dom';
import TabHeader from './TabHeader';
import "./horizionalTabs.css";

interface HorizontalTabsProps{
    children : JSX.Element[];
}

interface HorizontalTabsState{
    activeTab : string;
    redirect: string;
}

class HorizontalTabs extends React.Component<HorizontalTabsProps, HorizontalTabsState> {
    componentDidMount(): void {
        this.onClickTabItem(this.props.children[0].props.label);
    }

    onClickTabItem = (tab : string) => {
        let { activeTab } = this.state;

        if (activeTab !== tab) {
            activeTab = tab;
        }

        this.setState({ activeTab } );
    };

    state : HorizontalTabsState= {
        activeTab : "",
        redirect : ""
    }

    render() {
        const { children } = this.props;
        const { activeTab, redirect } = this.state;

        if (redirect !== "") return <Navigate to={redirect} />;
        
        const filteredTabs = children.filter( child => child.props.label === activeTab );
        
        const activeTabChildren = (filteredTabs?.length > 0) ? filteredTabs[0].props.children : <></>

        if (children?.length === 1) {
            return (<>{activeTabChildren}</>)
        }

        return (
            <>
                <div>
                    <ul className="tab-list">
                    {children.map((child) => {
                        const { label } = child.props;

                        return (
                            <TabHeader
                                isActive={label === activeTab}
                                key={label}
                                label={label}
                                onClick={this.onClickTabItem}
                            />
                        );
                    })}
                    </ul>
                </div>
                <div>
                      {activeTabChildren}
                </div>
            </>
        );
    }
}

export default HorizontalTabs;