import React from "react";
import Table, { PublishedTableProps } from "../../components/common/Table";
import Column from "../../components/common/columns";
import { ReadUserRole } from "../../services/securityService";
import authenticationService from "../../services/authenticationService";

class UserRolesTable extends React.Component<PublishedTableProps<ReadUserRole>> {
    columns : Column<ReadUserRole>[] = [
        { key: "roleName", label: "Role Name", order: "asc" },
    ];

    raiseSort = (sortColumn : Column<ReadUserRole>) => {
        this.setState({sortColumn});
        if ( this.props.onSort !== undefined)
            this.props.onSort(sortColumn);
    }

    render() { 
        const { data, sortColumn, onChangePage, onSearch, onDelete } = this.props;
        const doDelete = authenticationService.hasAccess("DeleteUserRole") ? onDelete : undefined;

        return <Table data={ data } keyName="id" columns={this.columns} sortColumn={sortColumn} onSort={this.raiseSort} onChangePage={onChangePage} onSearch={onSearch} onDelete={doDelete}/>;
    }
}
 
export default UserRolesTable;