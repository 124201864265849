import React from "react";
import Joi from "joi";
import Input, { InputType } from "./Input";
import ToggleSlider from "./ToggleSlider";
import Select, { Option } from "./Select";
import { GeneralIdRef } from "../../utils/GeneralIdRef";
import TemplateEditor from "./TemplateEditor";
import Button, { ButtonType } from "./Button";
import Expando from "./expando";
import InstrumentPicker from "../pickers/InstrumentPicker";
import UserPicker from "../pickers/UserPicker";
import RolePicker from "../pickers/RolePicker";
import ErrorBlock from "./ErrorBlock";
import { flushSync } from "react-dom";


export interface FormError {
    [key: string]: string;
}

export interface FormData {
    [key: string]: unknown;
}

export interface businessValidationError {
    path: string;
    message: string;
}

export interface businessValidationResult {
    details: businessValidationError[];
}

export interface propertyValue {
    name: string;
    value: string | boolean | number;
}

export interface joiSchema {
    [key: string]: object;
}

export interface FormState {
    data: FormData;
    errors: FormError;
    redirect?: string;
    delayValidation? : boolean;
}

export interface Match<P> {
    params: P;
    isExact: boolean;
    path: string;
    url: string;
}

export interface State {
    from: Location;
}

export interface LocationProps {
    hash: string;
    pathname: string;
    search: string;
    state: State;
}

export interface FormProps<P> {
    location: LocationProps;
    match: Match<P>;
    staticContext?: any;
}

class Form<P, FP extends FormProps<P>, FS extends FormState> extends React.Component<FP, FS> {
    schema: joiSchema = {};

    validate = (data: FormData) => {
        const options = { abortEarly: false };

        let schema = this.schema;
        const joiSchema = Joi.object(schema);
        const { error } = joiSchema.validate(data, options);
        let errors: FormError = {};

        const originalErrors = this.state.errors
        
        const keys = Object.keys(originalErrors).filter( x => x.startsWith("_"))
        for( var key of keys){
            errors[key] = originalErrors[key]
        }

        if (error) {
            if (error.details === undefined) {
                errors[error.name] = error.message;
            } else {
                for (let item of error.details) {
                    errors[item.path[0]] = item.message;
                }
            }
        }

        return errors;
    };

    async componentDidMount() {        
        await this.doMount();

        const { delayValidation } = this.state;

        if (!delayValidation)
        {
            const errors = this.validate(this.state.data);
            flushSync( () => { this.setState({ errors: errors });})
        }
    }

    doMount = async () => {};

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.setState( {delayValidation : false});

        const submitEvent = e.nativeEvent as SubmitEvent;

        const submitter = submitEvent.submitter as any;

        const errors = this.validate(this.state.data);
        this.setState({ errors: errors });

        const disabled = Object.keys(errors).filter( x => !x.startsWith("_general")).length > 0;

        if (disabled) return;

        this.clearGeneralError();
        this.doSubmit(submitter.name);
    };

    doSubmit = async (buttonName : string) => {};

    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget;

        const { delayValidation } = this.state;

        const data: FormData = { ...this.state.data };

        if ((input as any).type === InputType.checkbox) {
            data[input.name] = !data[input.name];
        }
        else {
            data[input.name] = input.value;
        }

        if (!delayValidation) {
            const errors = this.validate(data);
            this.setState({  errors });
        }

        this.setState({ data });
    };

    handleCustomFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget;

        const data: FormData = { ...this.state.data };

        if ((input as any).type === InputType.checkbox)
        {
            data[input.name] = !data[input.name];
        }
        else
            data[input.name] = {
                displayValue: input.value,
                value: input.value
            } ;

        const errors = this.validate(data);

        this.setState({ data, errors });
    };

    handleTemplateEditorChange = ( name : string, value : string ) => {
        const data: FormData = { ...this.state.data };

        data[name] = value

        const errors = this.validate(data);

        this.setState({ data, errors });
    }

    handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const input = e.currentTarget;

        const data: FormData = { ...this.state.data };
        data[input.name] = input.value;
        const errors = this.validate(data);

        this.setState({ data, errors });
    };

    handlePickerChange = (name : string, value : GeneralIdRef) => {
        const data: FormData = { ...this.state.data };
        data[name] = value;
        const errors = this.validate(data);

        this.setState({ data, errors });
    };

    handleUserPickerChange = (name : string, value : GeneralIdRef) => {
        const data: FormData = { ...this.state.data };
        data[name] = value;
        const errors = this.validate(data);

        this.setState({ data, errors });
    };
    
    handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.currentTarget;
        const { name, checked } = input;

        const data: FormData = { ...this.state.data };
        data[name] = checked;
        const errors = this.validate(data);

        this.setState({ data, errors });
    };

    clearGeneralError = () => { 
        const errors: FormError = { ...this.state.errors };

        if (errors.hasOwnProperty("_general")){
            delete errors._general
        }

        flushSync( () => { this.setState({ errors: errors });})
    }

    handleGeneralError = (ex : any) => {
        const errors: FormError = { ...this.state.errors };

        if (ex.response) {
            errors._general = ex.response.data.detail;
        }
        else{
            errors._general = ex.message;
        }

        flushSync( () => { this.setState({ errors: errors });})
    }

    handleFatalError = (ex : any) => {
        const errors: FormError = { ...this.state.errors };

        if (ex.response) {
            errors._fatal = ex.response.data.detail;
        }
        else{
            errors._fatal = ex.message;
        }

        flushSync( () => { this.setState({ errors: errors });})
    }

    renderLink(label: string, name? : string, to?:  string)
    {
        return (
            <Button className="btn-spaced" name={name ?? label} buttonType={ButtonType.primary} to={to}>
                {label}
            </Button>
        );
    }

    renderButton(label: string, name? : string, onClick? : (keyValue : any) => {}) {
        const { errors } = this.state;

        const disabled = Object.keys(errors).filter( x => !x.startsWith("_general")).length > 0;

        return (
            <Button className="btn-spaced" disabled={disabled} name={name ?? label} buttonType={ButtonType.primary} onClick={onClick}>
                {label}
            </Button>
        );
    }

    renderError(name: string) {
        const { errors } = this.state;      
        
        return (<>
                <ErrorBlock error={errors["_general"]}/>
                <ErrorBlock error={errors["_fatal"]}/>
            </>);
    }

    renderInput(name: string, label: string, type: InputType = InputType.text, readOnly = false, defaultValue : string = "") {
        const { data, errors } = this.state;

        let value = data[name];

        let cleanValue: string | undefined;
        if (value === undefined) {
            cleanValue = defaultValue;
        }
        else if (typeof value === "string") {
            cleanValue = value as string;
        }
        else if (typeof value === "number") {
            cleanValue = String(value);
        }
        else if (typeof value === "boolean") {
            cleanValue = String(value);
        }        

        if (readOnly) {
            return <Input type={type} name={name} label={label} value={cleanValue} error={errors[name]} readOnly />;
        } else {
            return <Input type={type} name={name} label={label} value={cleanValue} error={errors[name]} onChange={this.handleChange} />;
        }
    }

    renderCustomFieldInput(name: string, label: string, type: InputType = InputType.text, readOnly = false, defaultValue : string = "") {
        const { data, errors } = this.state;

        let value = data[name];

        let cleanValue: string | undefined;
        if (value === undefined) {
            cleanValue = defaultValue;
        }
        else if (typeof value === "string") {
            cleanValue = value as string;
        }
        else if (typeof value === "number") {
            cleanValue = String(value);
        }
        else if (typeof value === "boolean") {
            cleanValue = String(value);
        }        

        if (readOnly) {
            return <Input type={type} name={name} label={label} value={cleanValue} error={errors[name]} readOnly />;
        } else {
            return <Input type={type} name={name} label={label} value={cleanValue} error={errors[name]} onChange={this.handleCustomFieldChange} />;
        }
    }

    renderTemplateEditor(name: string, label: string, allowCustomFields : boolean) {
        const { data } = this.state;

        let value = (data[name] as string);

        return <div>
            <label htmlFor={name}>
                {label}
                <TemplateEditor name={name} data={value} onChange={this.handleTemplateEditorChange} showFields={allowCustomFields}/>
            </label>
        </div>
    }

    renderSelect(
        name : string,
        label : string,
        options : Option[]
        ) {
        const { data, errors } = this.state;

        return <Select name={name} label={label} value={data[name]} options={options} error={errors[name]} onChange={this.handleSelectChange}  />;
    }

    renderToggle(name: string, label: string) {
        const { data, errors } = this.state;
        return <ToggleSlider name={name} label={label} defaultChecked={Boolean(data[name])} error={errors[name]} onChange={this.handleToggleChange} />;
    }

    renderDropSection(name : string, title : JSX.Element, content: JSX.Element){
        const { errors } = this.state;
       
        return <Expando name={name} title={title} error={errors[name]}>{content}</Expando>
    }

    renderInstrumentPicker( name: string, label: string) {   
        const { data, errors } = this.state;
        
        return <InstrumentPicker name={name} label={label} value={data[name]} error={errors[name]} onChange={this.handlePickerChange} />
    }

    renderUserPicker( name: string, label: string) {   
        const { data, errors } = this.state;

        const glossaryValue : GeneralIdRef | undefined = ((data[name] as any)) as GeneralIdRef;

        return <UserPicker name={name} label={label} value={glossaryValue} error={errors[name]} onChange={this.handleUserPickerChange}/>;
    }

    renderRolePicker( name: string, label: string) {   
        const { data, errors } = this.state;

        const glossaryValue : GeneralIdRef | undefined = ((data[name] as any)) as GeneralIdRef;

        return <RolePicker name={name} label={label} value={glossaryValue} error={errors[name]} onChange={this.handleUserPickerChange}/>;
    }
}

export default Form;
