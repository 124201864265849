
export default function MapToJson ( map? : Map<string, string>){
    type keyValue = { [key: string]:string };

    let jsonObject : keyValue = {};
    map?.forEach((value, key) => {
        jsonObject[key] = value;
    });

    return JSON.stringify(jsonObject);
}