import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "/Diagnostic";

export interface ReadPerformanceReportSummary
{
    host : string,
    controllerName: string,
    actionName: string,
    requestType : string,
    count : number,
    minTotalTimeMs: number,
    maxTotalTimeMS : number,
    avgTotalTimeMs: number,
    sumTotalTimeMs : number
}

export interface ReadPerformanceReport{
    Id: number,
    host : string,
    controllerName: string,
    actionName: string,
    requestType : string,
    startDateTime : Date,
    totalTimeMS : number,
    timings : string
}

export async function GetPerformanceReportSummary( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadPerformanceReportSummary>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadPerformanceReportSummary>>(apiEndpoint + "/performanceReportSummary", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetPerformanceReports( hostName : string, controllerName : string, actionName : string, requestType : string, page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadPerformanceReport>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadPerformanceReport>>(apiEndpoint + "/performanceReportDetails", { params: {
        hostName : hostName,
        controllerName : controllerName,
        actionName : actionName,
        requestType : requestType,
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

const diagnosticService = {
    GetPerformanceReportSummary,
    GetPerformanceReports
};

export default diagnosticService;
