import { GeneralIdRef, MakeGeneralIdRef, MakeGeneralIdRefParams } from "../utils/GeneralIdRef";
import MapToJson from "../utils/MapToJson";
import { Paginated } from "./Paginated";
import httpService from "./httpService";

const apiEndpoint = "Security";

export interface RoleBase{
    name : string;
    isAdministrator : boolean;
    isSuperUser : boolean;
}

export interface ReadRole extends RoleBase{
    id : bigint;
    guid : string;
}

export interface EditRole extends RoleBase{
    id : GeneralIdRef;
}

export interface CreateRole extends RoleBase{
    guid? : string;
}

export interface UserRoleBase{
    roleId : GeneralIdRef,
    userId : GeneralIdRef
}

export interface ReadUserRole extends UserRoleBase{

}

export interface CreateUserRole{
    
}

export interface GetSecurityAccess
{
    securityAccess : string;
    name : string;
    description: string;
    groupName : string;
}

export interface GetRoleSecurityAccess extends GetSecurityAccess
{
    RoleId : GeneralIdRef
}


export async function GetRoles( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadRole>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadRole>>(apiEndpoint + "/roles", { params: {
        page: page,
        pageSize: pageSize,
        sortKey : sortKey,
        sortAscending: sortAscending,
        filters : filterString
    } } );
    
    return response.data;
}

export async function GetRole( id? : bigint, guid? : string ){
    const params = MakeGeneralIdRefParams(id, guid);

    const { data } = await httpService.get<ReadRole>(apiEndpoint + "/role?" + params );

    return data;
}

export async function PostRole( library : CreateRole): Promise<void>{
    await httpService.post(apiEndpoint + "/role", library);
}

export async function PutRole( library : EditRole ): Promise<void>{
    await httpService.put(apiEndpoint + "/role", library);
}

export async function DeleteRole( id? : bigint, guid?: string): Promise<void>{
    const generalIdRef = MakeGeneralIdRef( id, guid);

    await httpService.delete(apiEndpoint + "/role", { data: generalIdRef });
}

export async function GetUserRoles( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<ReadUserRole>> {
    const filterString = MapToJson(filters);
    const response = await httpService.get<Paginated<ReadUserRole>>(apiEndpoint + "/userRoles?page=" + page + "&pageSize=" + pageSize + "&sortKey="+sortKey+"&sortAscending="+sortAscending+"&filters="+filterString);

    return response.data;
}

export async function PostUserRole( userRole : CreateUserRole): Promise<void>{
    await httpService.post(apiEndpoint + "/userRole", userRole);
}

export async function DeleteUserRole( userId : GeneralIdRef, roleId: GeneralIdRef): Promise<void>{   
    await httpService.delete(apiEndpoint + "/userRole", { data : {
            userId,
            roleId
        }
    });
}

export async function GetAccessList( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<GetSecurityAccess>> {
    const filterString = MapToJson(filters);

    const response = await httpService.get<Paginated<GetSecurityAccess>>(apiEndpoint + "/accessList?page=" + page + "&pageSize=" + pageSize + "&sortKey="+sortKey+"&sortAscending="+sortAscending+"&filters="+filterString);
    return response.data;
}

export async function GetRoleAccess( page : number, pageSize : number, sortKey : string, sortAscending : boolean, filters? : Map<string, string> | undefined ): Promise<Paginated<GetRoleSecurityAccess>> {
    const filterString = MapToJson(filters);

    const response = await httpService.get<Paginated<GetRoleSecurityAccess>>(apiEndpoint + "/roleAccess?page=" + page + "&pageSize=" + pageSize + "&sortKey="+sortKey+"&sortAscending="+sortAscending+"&filters="+filterString);
    return response.data;
}

export async function AddRoleAccess( roleId: GeneralIdRef, additions : string[]): Promise<void>{   
    await httpService.post(apiEndpoint + "/roleAccess", { 
            roleId,
            securityAccess: additions
        }
    );
}

export async function DeleteRoleAccess( roleId: GeneralIdRef, deletions : string[]): Promise<void>{   
    await httpService.delete(apiEndpoint + "/roleAccess", { data : {
            roleId,
            securityAccess: deletions
        }
    });
}

const securityService = {
    GetRoles,
    GetRole,
    PostRole,
    PutRole,
    DeleteRole,
    GetUserRoles,
    PostUserRole,
    DeleteUserRole,
    GetAccessList,
    GetRoleAccess,
    AddRoleAccess,
    DeleteRoleAccess
};

export default securityService;
