import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import securityService, { CreateRole, CreateUserRole, EditRole } from "../services/securityService";
import { GeneralIdRef, MakeGeneralIdRef } from "../utils/GeneralIdRef";
import { getParentPath } from "../utils/getParentPath";

interface AddUserRoleState extends FormState {
    data: {
        roleId?: GeneralIdRef;
    };
    redirect: string;
}

class LocAddUserRole extends Form<any, any, AddUserRoleState> {
    state: AddUserRoleState = {
        data: {
            roleId: {id: BigInt(0)}
        },
        errors: {},
        redirect: "",
    };

    labelRoleId = "Role";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        roleId: Joi.object().required().label(this.labelRoleId)
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { userId } = this.props.router.params;
            const { roleId } = this.state.data;
            
            if (this.isEditMode()) {
                throw new Error("edit mode not implemented");
                // const { roleId } = this.props.router.params;

                // // const editRole : EditRole = {
                // //     id : MakeGeneralIdRef( roleId),
                // //     userId: name,
                // //     isAdministrator: false,
                // //     isSuperUser : false
                // // };

                // // await securityService.PutRole(editRole);
                // toast.info("User added to role edited");
            } else {
                const createUserRole : CreateUserRole = {
                    roleId: roleId!,
                    userId: MakeGeneralIdRef(BigInt(userId))
                }

                await securityService.PostUserRole(createUserRole);
                toast.info("User added to role");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/users/" + userId });
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        // const { roleId } = this.props.router.params;

        // if (roleId !== undefined) {
        //     // const loadedData = await securityService.GetRole(roleId);

        //     // const { data } = this.state;
            
        //     // data.userId = loadedData.name;

        //     //this.setState({ data });
        // }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Role to user</h1>
                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderRolePicker("roleId", this.labelRoleId)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const AddUserRole = withRouter(LocAddUserRole);

export default AddUserRole;
