import React from 'react';

interface TabProps{
    label: string;
    children : JSX.Element | JSX.Element[];
}

class Tab extends React.Component<TabProps> {
    render() {
        return (
            <div>
                
            </div>
        );
    }
}

export default Tab;