import react from 'react';
import Button, { ButtonType } from './Button';

export interface ConfirmButtonProps<T>{
    delayMS? : number;
    buttonType : ButtonType;
    keyValue: T;
    children: React.ReactNode;    
    confirmMessage?: React.ReactNode;
    onClick?: ( keyValue? : T ) => void;
}

export interface ConfirmButtonState{
    firstClick : boolean
}

class ConfirmButton<T> extends react.Component<ConfirmButtonProps<T>, ConfirmButtonState > {
    state : ConfirmButtonState = {
        firstClick : false
    }    

    FirstClick = () => {
        const firstClick = true;
        this.setState({firstClick});

        let { delayMS } = this.props;
        if (delayMS === undefined)
            delayMS = 5000;

        setTimeout(() => {
            const firstClick = false;
            this.setState({firstClick});
        }, delayMS);
    }

    SecondClick = () => {
       const {keyValue, onClick} = this.props;

        if (onClick)
            onClick(keyValue);
    }
    
    render() {
        const { buttonType, children, confirmMessage } = this.props;
        const { firstClick } = this.state;

        return (
            <>
                {!firstClick && <Button buttonType={buttonType} onClick={this.FirstClick}>{children}</Button>}
                {firstClick && <Button buttonType={ButtonType.danger} onClick={this.SecondClick}>{confirmMessage!==undefined?confirmMessage:"Are you sure?"}</Button>}
            </>
        );
    }
}

export default ConfirmButton;