import { Component } from 'react';
import Column from '../components/common/columns';
import withRouter from '../utils/withRouter';
import diagnosticService, { ReadPerformanceReport } from '../services/diagnosticService';
import { Paginated } from '../services/Paginated';
import PerformanceReportTable from './components/PerformanceReportTable';

interface PerformanceDetailsState{
    pagedData : Paginated<ReadPerformanceReport>,
    sortColumn : Column<ReadPerformanceReport>,
    filters: Map<string, string>;
}

class LocPerformanceDetails extends Component<any, PerformanceDetailsState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "id", label: "Id", order: "desc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;
        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSort = async(sortColumn : Column<ReadPerformanceReport>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        await this.updatePage(page, pageSize, sortColumn, filters);
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);
        await this.updatePage(page, pageSize, sortColumn, filters);
    };

    updatePage = async(page : number, pageSize : number, sortColumn : Column<ReadPerformanceReport>, filters : Map<string, string> ) =>
    {
        const { host, controller, action, requestType } = this.props.router.params;

        const pagedData = await diagnosticService.GetPerformanceReports(host, controller, action, requestType, page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ filters, pagedData, sortColumn });
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <PerformanceReportTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch}/>
            </div>
        );
    }
};

const PerformanceDetails = withRouter(LocPerformanceDetails);

export default PerformanceDetails;
