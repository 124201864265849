import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const useQuery = () => new URLSearchParams(useLocation().search);

export interface RouterProps{
    router: {
        location : any,
        navigate : any,
        params : any,
        query : any
    }
}

/* This is a higher order component that
 *  inject a special prop   to our component.
 */
export default function withRouter(Component: any) {
    function ComponentWithRouter(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        let query = useQuery();
        return <Component {...props} router={{ location, navigate, params, query }} />;
    }
    return ComponentWithRouter;
}

