import React, { Component } from 'react';
import Column from '../components/common/columns';
import { Paginated } from '../services/Paginated';
import UsersTable from './components/usersTable';
import userService, { GetUser } from '../services/usersService';
import Button, { ButtonType } from '../components/common/Button';
import Permission from '../components/common/Permission';
import { toast } from 'react-toastify';

interface UsersState{
    pagedData : Paginated<GetUser>,
    sortColumn : Column<GetUser>,
    filters: Map<string, string>;
}

class Users extends Component< any, any, UsersState> {
    state = {
        pagedData : { page: 1,
            pageSize : 10,
            count: 0,
            totalPages: 1,
            data: [] },
        sortColumn: { key: "displayName", label: "Name", order: "asc" },
        filters: new Map<string, string>()
    }

    componentDidMount = async () => {
        const { page, pageSize } = this.state.pagedData;

        await this.changePage(page, pageSize);
    }

     changePage = async(page: number, pageSize : number) =>{
        const { sortColumn, filters } = this.state;
        
        const pagedData = await userService.getUsers(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters );
        this.setState({ pagedData });
    }

    onSort = async(sortColumn : Column<GetUser>) => {
        const {page, pageSize } = this.state.pagedData;
        const { filters } = this.state;
        const pagedData = await userService.getUsers(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters);

        this.setState({pagedData, sortColumn});
    }

    onSearch = async ( name: string, value: string) => {
        const {page, pageSize } = this.state.pagedData;
        const {sortColumn, filters } = this.state;
        filters.set(name, value);

        const pagedData = await userService.getUsers(page, pageSize, sortColumn.key, sortColumn.order === "asc", filters);

        this.setState( { filters, pagedData });
    };

    onDelete = async ( keyValue? : GetUser) => {
        await userService.deleteUser( keyValue!.id, keyValue!.guid);

        toast.success( "User " + keyValue?.displayName + " deleted");
        this.componentDidMount();
    }

    resentConfirmEmail = async (user: GetUser) => {
        await userService.resendConfirmEmail( user.id, user.guid);
        toast.info("Confirm e-mail resent");
    }

    render(): JSX.Element {
        const { pagedData, sortColumn } = this.state;

        return (
            <div>
                <Permission privilegeKey="AddUser">
                    <div>
                        <Button buttonType={ButtonType.primary} to="add">Add</Button>
                    </div>
                    <hr/>
                </Permission>
                <UsersTable data={pagedData} sortColumn={sortColumn} onChangePage={this.changePage} onSort={this.onSort} onSearch={this.onSearch} onDelete={this.onDelete} resendConfirmEmail={this.resentConfirmEmail}/>
            </div>
        );
    }
};

export default Users;
