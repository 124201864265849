import React from "react";
import Toggle, { ToggleProps } from "react-toggle";
import "react-toggle/style.css";
import ErrorBlock from "./ErrorBlock";

export interface ToggleSliderProps extends ToggleProps {
    name: string;
    label: string;
    error: string;
    readOnly?: boolean;
    defaultChecked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

class ToggleSlider extends React.Component<ToggleSliderProps> {
    render() {
        const { name, label, error, readOnly, defaultChecked, ...rest } = this.props;

        return (
            <div className="form-group">
                <label htmlFor={name}>{label}</label>
                <Toggle {...rest} id={name} name={name} checked={defaultChecked} />
                <ErrorBlock error={error}/>
            </div>
        );
    }
}

export default ToggleSlider;
