import React from "react";

export interface Option{
    _id : string;
    name : string;
}

export interface SelectProps {
    name : string, 
    label : string,
    error? : string,
    value : unknown
    options? : Option[],
    includeBlankFirstEntry? : boolean,
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
 
function GenerateValue( value : unknown )
{
    let actualValue = value;
        
    if (value === true)
        return "true";
    if (value === false)
        return "false";

    return actualValue as string | number | readonly string[] | undefined;
}

class Select extends React.Component<SelectProps> {    
    render() { 
        const { name, label, error, value, options, includeBlankFirstEntry, onChange, ...rest } = this.props;

        const actualValue = GenerateValue( value);
        
        return (
            <div className="form-group">
                <label htmlFor={name}>{label}</label>
                <select {...rest} id={name} value={actualValue} className="form-control" name={name} onChange={onChange}>
                    {includeBlankFirstEntry && <option value=""/>}
                    {options?.map(({ _id, name }) => (
                        <option key={_id} value={_id}>
                            {name}
                        </option>
                    ))}
                </select>

                {error && <div className="alert alert-danger">error</div>}
            </div>
        );
    }
};

export default Select;