import { faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, Component } from "react";
import Column from "./columns";
import Input, { InputType } from "./Input";

export interface TableHeaderProps<T>{
    sortColumn? : Column<T>;
    columns : Column<T>[];
    showDelete? : boolean;
    showEdit? : boolean;
    showAudit? : boolean;
    showSecondaryAudit? : boolean;    
    onSort? : (sortColumn : Column<T>) => void;
    onSearch?: ( name: string, value: string) => void;    
}

class TableHeader<T> extends Component<TableHeaderProps<T>> {
    columnsMatch = ( left? : Column<T>, right? : Column<T>) =>
    {
        if (left?.key !== right?.key) return false;
        return true;
    }
    
    raiseSort = (column : Column<T>) => {
        let sortColumn = this.props.sortColumn;

        if (sortColumn) {
            if (this.columnsMatch(column, sortColumn)){
                sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
            }
            else {
                sortColumn = column;
                sortColumn.order = "asc";
            }

            const { onSort } = this.props;

            if (onSort)
                onSort(sortColumn);
        }
    };

     renderSortIcon = (column : Column<T>) => {
        const { sortColumn } = this.props;

        if (!sortColumn) return null;

        if (!this.columnsMatch(column, sortColumn)) return null;

        if (sortColumn?.order === "asc") return <FontAwesomeIcon icon={faSortAsc}/>

        return <FontAwesomeIcon icon={faSortDesc}/>
     };

    changeSearch = (e: ChangeEvent<HTMLInputElement>) => 
    {
        const {onSearch} = this.props;

        if (onSearch)
            onSearch(e.currentTarget.name, e.currentTarget.value);
    };

    render() {
        const { columns, showEdit, showDelete, showAudit, showSecondaryAudit, onSearch } = this.props;

        let searchRow = <></>;
        if (onSearch)
                searchRow = <tr>
                {columns.map((column) =>
                        <th key={column.path || column.key}>
                            {                            
                            (column.searchable === undefined || column.searchable === true ) &&                           
                                <Input name={column.path || column.key} label={""} error={""} type={InputType.text} onChange={this.changeSearch} />
                            }
                        </th>
                )}                
                {showEdit && <th></th>}
                {showDelete && <th></th>}
                {showAudit && <th></th>}
                {showAudit && showSecondaryAudit && <th></th>}
            </tr>;

        return (
            <thead>
                <tr>
                    {columns.map((column) =>
                            <th className="text-nowrap" key={column.path || column.key} scope="col" onClick={() => this.raiseSort(column)}>
                                {column.label} {this.renderSortIcon(column)}
                            </th>
                    )}
                    {showEdit && <th scope="col"></th>}
                    {showDelete && <th scope="col"></th>}
                    {showAudit && <th scope="col"></th>}
                    {showAudit && showSecondaryAudit && <th scope="col"></th>}
                </tr>
                {searchRow}
            </thead>
        );
    }
}

export default TableHeader;
