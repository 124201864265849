import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import libraryService, { CreateLibrary, EditLibrary } from "../services/libraryService";
import withRouter from "../utils/withRouter";
import { InputType } from "../components/common/Input";
import { getParentPath } from "../utils/getParentPath";

interface LibraryDetailsState extends FormState {
    data: {
        name: string;
        folderName: string;
        published: boolean;
    };
    redirect: string;
}

class LocLibraryDetails extends Form<any, any, LibraryDetailsState> {
    state: LibraryDetailsState = {
        data: {
            name: "",
            folderName: "",
            published: true
        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    labelFolderName = "Folder Name";
    labelPublished = "Published";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName),
        folderName: Joi.string().required().max(450).label(this.labelFolderName),
        published: Joi.boolean().required().label(this.labelPublished),
    };

    doSubmit = async (buttonName : string) => {
        try {
            const { name, folderName, published } = this.state.data;

            if (this.isEditMode()) {
                const { libraryId } = this.props.router.params;

                const editLibrary : EditLibrary = {
                    id : libraryId,
                    name: name,
                    folderName: folderName,
                    published : published
                };

                await libraryService.PutLibrary(editLibrary);
                toast.info("Library edited");
            } else {
                const createLibrary : CreateLibrary = {                    
                    name: name,
                    folderName: folderName,
                    published : published      
                }

                await libraryService.PostLibrary(createLibrary);
                toast.info("New library added");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/library" });
        }
        catch (ex: any) {
            this.handleGeneralError(ex)
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const { libraryId } = this.props.router.params;

        if (libraryId !== undefined) {
            try {
                const loadedData = await libraryService.GetLibrary(libraryId);

                const { data } = this.state;
            
                data.name = loadedData.name;
                data.folderName = loadedData.folderName;
                data.published = loadedData.published;

                this.setState({ data });
            }
            catch (ex: any) {
                this.handleFatalError(ex)
            }
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;

        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Library</h1>
                <form className="editLibrary" onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName)}
                    {this.renderInput("folderName", this.labelFolderName)}
                    {this.renderInput("published", this.labelPublished, InputType.checkbox)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const LibraryDetails = withRouter(LocLibraryDetails);

export default LibraryDetails;
