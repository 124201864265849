import * as React from "react";

function EnvPage() {
    return (
        <>
            <p>This is the Environment</p>
            <br></br>
            <p>window.__RUNTIME_CONFIG__.API_URL = {window.__RUNTIME_CONFIG__.API_URL}</p>
        </>
    );
}

export default EnvPage;
