import Form, { FormState, FormData, businessValidationResult } from "../components/common/Form";
import Joi from "joi";
import authentication from "../services/authenticationService";
import { IEmailUserAction } from "../models/IEmailUserAction";
import { InputType } from "../components/common/Input";
import Button, { ButtonType } from "../components/common/Button";

export interface EmailUserActionConfirmEmailData extends FormData {
    password: string;
    confirmPassword: string;
    emailConfirmed: boolean;
}

export interface EmailUserActionConfirmEmailState extends FormState {
    data: EmailUserActionConfirmEmailData;
}

class EmailUserActionConfirmEmail extends Form<any, any, EmailUserActionConfirmEmailState> {
    state = {
        data: { password: "", confirmPassword: "", emailConfirmed: false },
        errors: {},
    };

    labelPassword = "Password";
    labelConfirmPassword = "Confirm Password";
    labelConfirmEmail = "Confirm Email amd save password";

    schema = {
        password: Joi.string().required().min(5).label(this.labelPassword),
        confirmPassword: Joi.string()
            .when("password", {
                is: "",
                then: Joi.optional(),
                otherwise: Joi.valid(Joi.ref("password")).error(() => {
                    const e = new Error("Passwords must match");
                    e.name = "confirmPassword";
                    return e;
                }),
            })
            .label(this.labelConfirmPassword),

        emailConfirmed: Joi.boolean(),
    };

    BusinessValidation(): businessValidationResult | null {
        const { password, confirmPassword } = this.state.data;

        if (password !== confirmPassword) {
            return {
                details: [
                    {
                        path: "confirmPassword",
                        message: "You need to confirm by typing exactly the same as the new password",
                    },
                ],
            };
        }

        return null;
    }

    doSubmit = async (buttonName : string) => {
        const { emailUserAction } = this.props;
        const { password } = this.state.data;

        const action: IEmailUserAction = {
            email: emailUserAction.email,
            token: emailUserAction.token,
            password: password,
            emailActionType: emailUserAction.emailActionType,
        };

        try {
            const callResult = await authentication.completeEmailAction(action);
            if (callResult === 1) {
                let data = { ...this.state.data };
                data.emailConfirmed = true;
                this.setState({ data });
            }
        }
        catch(ex:any) {
            this.handleGeneralError(ex);
        }
    };

    render() {
        const { emailConfirmed } = this.state.data;

        if (emailConfirmed) {
            return (
                <>
                    <div>Success, your e-mail is confirmed. You can now log in.</div>
                    <Button buttonType={ButtonType.link} to="/">Login</Button>
                </>
            );
        }

        return (
            <>
                <div>To confirm your e-mail account please enter a new password</div>

                <form onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("password", this.labelPassword, InputType.password)}
                    {this.renderInput("confirmPassword", this.labelConfirmPassword, InputType.password)}
                    {this.renderButton(this.labelConfirmEmail)}
                </form>
            </>
        );
    }
}

export default EmailUserActionConfirmEmail;
