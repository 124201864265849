import { Buffer } from "buffer";
import { Navigate, useParams } from "react-router-dom";
import { IEmailUserAction, EmailActionType } from "../models/IEmailUserAction";
import EmailUserActionDiableTwoFactorAuthentication from "./EmailUserActionDisableTwoFactorAuthentication";
import EmailUserActionConfirmEmail from "./EmailUserActionConfirmEmail";
import EmailUserActionPasswordReset from "./EmailUserActionPasswordReset";

function EmailUserAction() {
    let { token } = useParams();

    if (token) {
        const base64ToString = Buffer.from(token, "base64").toString();
        const emailUserAction: IEmailUserAction = JSON.parse(base64ToString);

        switch (emailUserAction.emailActionType) {
            case EmailActionType.PasswordReset:
                return <EmailUserActionPasswordReset emailUserAction={emailUserAction} />;
            case EmailActionType.DisableAuthenticator:
                return <EmailUserActionDiableTwoFactorAuthentication emailUserAction={emailUserAction} />;
            case EmailActionType.ConfirmEmailAddress:
                return <EmailUserActionConfirmEmail emailUserAction={emailUserAction} />;
        }
    }

    return <Navigate to="/404" />;
}

export default EmailUserAction;
