import Joi from "joi";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Form, { FormError, FormState } from "../components/common/Form";
import withRouter from "../utils/withRouter";
import InstrumentSynonymService, { CreateInstrumentSynonym, EditInstrumentSynonym } from "../services/instrumentSynonymService";
import { MakeGeneralIdRef } from "../utils/GeneralIdRef";
import { getParentPath } from "../utils/getParentPath";

interface InstrumentSynonymDetailsState extends FormState {
    data: {
        name: string;
    };
    redirect: string;
}

class LocInstrumentSynonymDetails extends Form<any, any, InstrumentSynonymDetailsState> {
    state: InstrumentSynonymDetailsState = {
        data: {
            name: ""
        },
        errors: {},
        redirect: "",
    };

    labelName = "Name";
    
    labelApply = "Save";
    labelSave = "Save and close";

    schema = {
        name: Joi.string().required().max(450).label(this.labelName)
    };

    InstrumentId = () => {
        const { instrumentId } = this.props.router.params;
        return instrumentId;
    }

    doSubmit = async (buttonName : string) => {
        try {
            const { name } = this.state.data;

            const instrumentId = MakeGeneralIdRef(this.InstrumentId())

            if (this.isEditMode()) {
                const { instrumentId } = this.props.router.params;

                const edit : EditInstrumentSynonym = {
                    id : instrumentId,
                    name: name,
                    instrumentId: instrumentId
                };

                await InstrumentSynonymService.PutInstrumentSynonym(edit);
                toast.info("Edited");
            } else {
                const create : CreateInstrumentSynonym = {
                    name: name,
                    instrumentId: instrumentId
                }

                await InstrumentSynonymService.PostInstrumentSynonym(create);
                toast.info("Added");
            }

            if (buttonName === this.labelSave)
                this.setState({ redirect: "/instrument/" + this.InstrumentId() });
        }
        catch (ex: any) {
            this.handleGeneralError(ex)
        }
    };

    isEditMode = () => {
        const { editMode } = this.props;
        return editMode;
    };

    doMount = async () => {
        const { synonymId } = this.props.router.params;

        if (synonymId !== undefined) {
            try {
                const loadedData = await InstrumentSynonymService.GetInstrumentSynonym(synonymId);

                const { data } = this.state;

                data.name = loadedData.name;     

                this.setState({ data });
            }
            catch (ex: any) {
                this.handleFatalError(ex)
            }
        }
    };

    render() {
        const { redirect } = this.state;
        if (redirect !== "") return <Navigate to={redirect} />;
        
        const link = this.props.router.location.pathname;
        let parent;

        let mode = "Add";
        if (this.isEditMode()){
            mode = "Edit";
            parent = getParentPath(link, 2);
        } 
        else{
            parent = getParentPath(link);
        }

        return (
            <div>
                <h1>{mode} Instrument Synonym</h1>
                <form className="editInstrumentSynonym" onSubmit={this.handleSubmit}>
                    {this.renderError("_general")}
                    {this.renderInput("name", this.labelName)}
                    
                    {this.renderLink("Cancel",undefined,parent)}
                    {this.isEditMode() && this.renderButton(this.labelApply)}
                    {this.renderButton(this.labelSave)}
                </form>
            </div>
        );
    }
}

const InstrumentSynonymDetails = withRouter(LocInstrumentSynonymDetails);

export default InstrumentSynonymDetails;
