import * as React from "react";

function HomePage() {
    return (
        <>
            <div><img width="100%" height="100%" src="Music Library.jpg" aria-hidden="true" alt="Music library" /></div>
        </>
    );
}

export default HomePage;
